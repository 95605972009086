import React, { useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Button,
} from "reactstrap"

const FileUpload = () => {
  const [imageFile, setImageFile] = useState(null)
  const [videoFile, setVideoFile] = useState(null)
  const [imagePreview, setImagePreview] = useState(null)
  const [videoPreview, setVideoPreview] = useState(null)

  // Handle image upload
  const handleImageUpload = e => {
    const file = e.target.files[0]
    if (file && file.type.startsWith("image/")) {
      setImageFile(file)
      setImagePreview(URL.createObjectURL(file))
    } else {
      alert("Please upload a valid image file.")
    }
  }

  // Handle video upload
  const handleVideoUpload = e => {
    const file = e.target.files[0]
    if (file && file.type.startsWith("video/")) {
      setVideoFile(file)
      setVideoPreview(URL.createObjectURL(file))
    } else {
      alert("Please upload a valid video file.")
    }
  }

  // Handle form submission
  const handleSubmit = async e => {
    e.preventDefault()

    if (!imageFile || !videoFile) {
      alert("Please upload both an image and a video.")
      return
    }

    const formData = new FormData()
    formData.append("image", imageFile)
    formData.append("video", videoFile)

    try {
      const response = await fetch("https://your-api-url.com/api/upload", {
        method: "POST",
        body: formData,
      })

      if (response.ok) {
        const data = await response.json()
        alert("Files uploaded successfully!")
        console.log("Image Path:", data.imagePath)
        console.log("Video Path:", data.videoPath)
      } else {
        alert("Failed to upload files.")
      }
    } catch (error) {
      console.error("Error uploading files:", error)
      alert("An error occurred while uploading files.")
    }
  }

  return (
    <Container>
      <Row className="justify-content-center">
        <Col>
          <Card>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <Row>
                  {/* Image Upload */}
                  <Col md={12}>
                    <FormGroup>
                      <Label for="imageUpload">Upload Image</Label>
                      <Input
                        type="file"
                        id="imageUpload"
                        accept="image/*"
                        onChange={handleImageUpload}
                        capture="environment"
                      />
                      {imagePreview && (
                        <div className="mt-3">
                          <img
                            src={imagePreview}
                            alt="Preview"
                            style={{ width: "100%", borderRadius: "10px" }}
                          />
                        </div>
                      )}
                    </FormGroup>
                  </Col>

                  {/* Video Upload */}
                  <Col md={12}>
                    <FormGroup>
                      <Label for="videoUpload">Upload Video</Label>
                      <Input
                        type="file"
                        id="videoUpload"
                        accept="video/*"
                        onChange={handleVideoUpload}
                        capture="camcorder"
                      />
                      {videoPreview && (
                        <div className="mt-3">
                          <video
                            controls
                            style={{ width: "100%", borderRadius: "10px" }}
                          >
                            <source src={videoPreview} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>

                <Button type="submit" color="primary" className="mt-3">
                  Submit
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default FileUpload

// import React, { useState } from "react"
// import {
//   Card,
//   CardBody,
//   Col,
//   Container,
//   Form,
//   FormGroup,
//   Input,
//   Label,
//   Row,
//   Button,
// } from "reactstrap"

// const FileUpload = () => {
//   const [imageFile, setImageFile] = useState(null)
//   const [videoFile, setVideoFile] = useState(null)
//   const [imagePreview, setImagePreview] = useState(null)
//   const [videoPreview, setVideoPreview] = useState(null)

//   // Handle image upload
//   const handleImageUpload = e => {
//     const file = e.target.files[0]
//     if (file && file.type.startsWith("image/")) {
//       setImageFile(file)
//       console.log(file, "file")
//       setImagePreview(URL.createObjectURL(file))
//     } else {
//       alert("Please upload a valid image file.")
//     }
//   }

//   // Handle video upload
//   const handleVideoUpload = e => {
//     const file = e.target.files[0]
//     if (file && file.type.startsWith("video/")) {
//       setVideoFile(file)
//       setVideoPreview(URL.createObjectURL(file))
//       console.log(file, "file")
//     } else {
//       alert("Please upload a valid video file.")
//     }
//   }

//   // Handle form submission
//   const handleSubmit = async e => {
//     e.preventDefault()

//     if (!imageFile || !videoFile) {
//       alert("Please upload both an image and a video.")
//       return
//     }

//     const formData = new FormData()
//     formData.append("image", imageFile)
//     formData.append("video", videoFile)

//     try {
//       const response = await axios.post("/api/upload", formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       })

//       if (response.status === 200) {
//         const { imagePath, videoPath } = response.data
//         alert("Files uploaded successfully!")
//         console.log("Image Path:", imagePath)
//         console.log("Video Path:", videoPath)
//         // Save imagePath and videoPath in your database or state
//       } else {
//         alert("Failed to upload files.")
//       }
//     } catch (error) {
//       console.error("Error uploading files:", error)
//       alert("An error occurred while uploading files.")
//     }
//   }
//   return (
//     <Container>
//       <Row className="justify-content-center">
//         <Col>
//           <Card>
//             <CardBody>
//               <Form onSubmit={handleSubmit}>
//                 <Row>
//                   {/* Image Upload */}
//                   <Col md={6}>
//                     <FormGroup>
//                       <Label for="imageUpload">Upload Image</Label>
//                       <Input
//                         type="file"
//                         id="imageUpload"
//                         accept="image/*"
//                         onChange={handleImageUpload}
//                         capture="environment"
//                       />
//                       {imagePreview && (
//                         <div className="mt-3">
//                           <img
//                             src={imagePreview}
//                             alt="Preview"
//                             style={{ width: "100%", borderRadius: "10px" }}
//                           />
//                         </div>
//                       )}

//                       {/* <p>{imageFile}</p> */}
//                     </FormGroup>
//                   </Col>

//                   {/* Video Upload */}
//                   <Col md={6}>
//                     <FormGroup>
//                       <Label for="videoUpload">Upload Video</Label>
//                       <Input
//                         type="file"
//                         id="videoUpload"
//                         accept="video/*"
//                         onChange={handleVideoUpload}
//                         capture="camcorder"
//                       />
//                       {videoPreview && (
//                         <div className="mt-3">
//                           <video
//                             controls
//                             style={{ width: "100%", borderRadius: "10px" }}
//                           >
//                             <source src={videoPreview} type="video/mp4" />
//                             Your browser does not support the video tag.
//                           </video>
//                         </div>
//                       )}
//                       {/* <p>{videoFile}</p> */}
//                     </FormGroup>
//                   </Col>
//                 </Row>

//                 <Button type="submit" color="primary" className="mt-3">
//                   Submit
//                 </Button>
//               </Form>
//             </CardBody>
//           </Card>
//         </Col>
//       </Row>
//     </Container>
//   )
// }

// export default FileUpload
