// import React, { useState, useEffect } from "react"
// import VideoCall from "./VideoCall"
// import { initializeZego, loginRoom, listenForCallSignal } from "./ZegoService"
// import { Card, Col } from "reactstrap"

// const Receiver = () => {
//   const [isReceivingCall, setIsReceivingCall] = useState(false)
//   const [acceptCall, setAcceptCall] = useState(false)
//   const [callerInfo, setCallerInfo] = useState(null)

//   const roomID = "room01" // Replace with a valid Room ID
//   const userID = "user2" // Replace with a unique User ID for the receiver
//   const userName = "Receiver" // Replace with the receiver's display name
//   const appID = 1233739532
//   const serverSecret = "bd4fc7c7e896e364e33a6395cb09d6ed"
//   useEffect(() => {
//     const setupZego = async () => {
//       try {
//         const zegoClient = initializeZego()

//         console.log(
//           "Room ID:",
//           roomID,
//           "User ID:",
//           userID,
//           "User Name:",
//           userName
//         )

//         // Login to the room
//         await loginRoom(zegoClient, roomID, userID, userName)

//         // Listen for incoming call signals
//         listenForCallSignal(zegoClient, userID, callerData => {
//           console.log("Incoming call from:", callerData)
//           setCallerInfo(callerData)
//           setIsReceivingCall(true)
//         })
//       } catch (error) {
//         console.error("Error setting up Zego for Receiver:", error.message)
//       }
//     }

//     setupZego()
//   }, [])

//   const handleAcceptCall = () => {
//     setAcceptCall(true)
//     setIsReceivingCall(false)
//     console.log("Call accepted.")
//   }

//   return (
//     <div>
//       <Col>
//         <Card>
//           {!acceptCall ? (
//             <div style={{ textAlign: "center", marginTop: "10px" }}>
//               {isReceivingCall ? (
//                 <>
//                   <h3>
//                     Incoming Call from {callerInfo?.userName || "Unknown"}
//                   </h3>
//                   <button
//                     onClick={handleAcceptCall}
//                     style={{
//                       padding: "10px 20px",
//                       backgroundColor: "green",
//                       color: "white",
//                       border: "none",
//                       borderRadius: "5px",
//                       cursor: "pointer",
//                     }}
//                   >
//                     Accept Call
//                   </button>
//                 </>
//               ) : (
//                 <h3>Waiting for a Call...</h3>
//               )}
//             </div>
//           ) : (
//             <VideoCall
//               roomID={roomID}
//               userID={userID}
//               userName={userName}
//               appID={appID}
//               serverSecret={serverSecret}
//             />
//           )}
//         </Card>
//       </Col>
//     </div>
//   )
// }

// export default Receiver

import React, { useState, useEffect } from "react"
import VideoCall from "./VideoCall"
import { Card, CardBody, Col } from "reactstrap"
import { v4 as uuidv4 } from "uuid"

const Receiver = () => {
  const [isReceivingCall, setIsReceivingCall] = useState(false)
  const [acceptCall, setAcceptCall] = useState(false)
  const [callerVideoStarted, setCallerVideoStarted] = useState(false)
  // const [audio] = useState(new Audio("/sos.mp3")) // Ringtone file
  const [audio] = useState(new Audio("")) // Ringtone file

  const roomID = "room01" // Room ID
  // const userID = "user2" // Unique User ID
  // const userName = "Receiver" // Receiver's name

  const userID = uuidv4() // Unique User ID
  const userName = generateUniqueIdentifier("Receiver") // "receiver_bfc800c0-0b8a-4e33-a9dc-9a39f7ac3dda"

  const appID = 1233739532 // ZEGOCLOUD appID
  const serverSecret = "bd4fc7c7e896e364e33a6395cb09d6ed" // Your server secret

  function generateUniqueIdentifier(userName) {
    // Convert the user name to lowercase and remove spaces
    const cleanedUserName = userName.toLowerCase().replace(/\s/g, "")

    // Generate a random UUID
    const uuid = uuidv4()

    // Combine the user name and UUID
    const uniqueIdentifier = `${cleanedUserName}_${uuid}`

    return uniqueIdentifier
  }

  useEffect(() => {
    // Simulate receiving a call after 3 seconds
    const callTimeout = setTimeout(() => {
      setIsReceivingCall(true)
      // Simulate the caller starting the video after 2 seconds
      setTimeout(() => {
        setCallerVideoStarted(true)
      }, 2000)
    }, 3000)

    return () => {
      clearTimeout(callTimeout)
      audio.pause()
      audio.currentTime = 0 // Reset audio
    }
  }, [audio])

  const handleAcceptCall = () => {
    setAcceptCall(true)
    setIsReceivingCall(false)
    setCallerVideoStarted(false) // Stop blinking icon on accept
    audio.pause()
    audio.currentTime = 0
  }

  useEffect(() => {
    if (callerVideoStarted && isReceivingCall) {
      audio.loop = true
      audio.play().catch(err => console.error("Audio playback error:", err))
    } else {
      audio.pause()
      audio.currentTime = 0
    }
  }, [callerVideoStarted, isReceivingCall, audio])

  return (
    <div>
      <div>
        <h3
          className=" d-flex flex-column align-items-center mb- mt-4"
          style={{
            color: "red",
            // fontSize: window.innerWidth < 768 ? 18 : 24,
          }}
        >
          Command Center
        </h3>

        <Card style={{ width: "100%", minHeight: "400px" }}>
          {!acceptCall ? (
            <Card>
              <CardBody>
                <div style={{ textAlign: "center", marginTop: "50px" }}>
                  {isReceivingCall ? (
                    <>
                      <h3>Connect To Command Center</h3>
                      {callerVideoStarted && (
                        <div
                          style={{
                            margin: "20px 0",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {/* Blinking Alarm Icon */}
                          <div
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                              backgroundColor: "red",
                              animation: "blink 1s infinite",
                            }}
                          ></div>
                          <style>
                            {`
                        @keyframes blink {
                          0%, 100% { opacity: 1; }
                          50% { opacity: 0; }
                        }
                        `}
                          </style>
                        </div>
                      )}
                      <button
                        onClick={handleAcceptCall}
                        style={{
                          padding: "10px 20px",
                          margin: "10px",
                          backgroundColor: "green",
                          color: "white",
                          border: "none",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        Join
                      </button>
                    </>
                  ) : (
                    <h3>Connecting to Command Center...</h3>
                  )}
                </div>
              </CardBody>
            </Card>
          ) : (
            <VideoCall
              roomID={roomID}
              userID={userID}
              userName={userName}
              appID={appID}
              serverSecret={serverSecret}
            />
          )}
        </Card>
      </div>
    </div>
  )
}

export default Receiver
