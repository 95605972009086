// src/components/filter.
import React, { useMemo, useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"
import UpArrow from "../../assets/images/uparrow.svg"
import DownArrow from "../../assets/images/downarrow.svg"
import CallDoctor from "../../assets/images/health/NursingStation/call doctor.png"
import GeneralExam from "../../assets/images/health/NursingStation/checklist.png"
import SystematicExam from "../../assets/images/health/NursingStation/systematicExam.png"

import Collaborate from "../../assets/images/health/NursingStation/Collaborate.png"
import Mortality from "../../assets/images/health/NursingStation/mortality.png"
import SOS from "../../assets/images/health/NursingStation/sos.png"
import Transfer from "../../assets/images/health/NursingStation/Transfer.png"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Row } from "reactstrap"
import Caller from "pages/VideoCalling/comp/Zego-cloud/VideoCall/Caller"

function NursingStation() {
  const CountdownCell = ({ value }) => {
    const [timeLeft, setTimeLeft] = useState(20 * 60) // 20 minutes in seconds

    useEffect(() => {
      if (timeLeft <= 0) return

      const timer = setInterval(() => {
        setTimeLeft(prevTime => prevTime - 1)
      }, 1000)

      return () => clearInterval(timer)
    }, [timeLeft])

    const formatTime = seconds => {
      const minutes = Math.floor(seconds / 60)
      const remainingSeconds = seconds % 60
      return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
        .toString()
        .padStart(2, "0")}`
    }

    return (
      <span style={{ color: "red", fontFamily: "fantasy", fontSize: 20 }}>
        {formatTime(timeLeft)}
      </span>
    )
  }

  const columns = useMemo(
    () => [
      // {
      //   Header: "S/N",
      //   accessor: "serialNumber",
      //   Cell: ({ row }) => (
      //     <div style={{ textAlign: "center" }}>{row.index + 1}</div>
      //   ), // Adding 1 to make it a 1-based index
      // },
      {
        Header: "Bed No.",
        accessor: "bedNo",
        Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>,
      },
      {
        Header: "UHID No.",
        accessor: "uhidNo",
        Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>,
      },
      {
        Header: "Patient Name",
        accessor: "patientName",
        Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>,
      },
      {
        Header: "Gender",
        accessor: "gender",
        Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>,
      },
      {
        Header: "Age (yrs)",
        accessor: "age",
        Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>,
      },
      {
        Header: "Admission Date",
        accessor: "admissionDate",
        Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>,
      },
      {
        Header: "Doctor Name",
        accessor: "doctorName",
        Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>,
      },
      // {
      //   Header: "SOS",
      //   accessor: "sosIcon",
      //   Cell: ({ value }) => {
      //     const Icon =
      //       "https://static.vecteezy.com/system/resources/thumbnails/005/568/095/small_2x/sos-bell-in-red-circle-icon-alarm-bell-symbol-isolated-on-white-background-free-vector.jpg "
      //     return (
      //       <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
      //         <img
      //           src={Icon}
      //           alt="Icon"
      //           style={{ width: "50px", height: "50x" }}
      //         />
      //       </div>
      //     )
      //   },
      // },
      {
        Header: "Next Dose Time",
        accessor: "nextDoseTime",
        Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>,
      },
      {
        Header: "Medication Transfusion Counter",
        accessor: "medTransfusionCounter",
        Cell: ({ row }) => {
          return (
            <div
              style={{
                textAlign: "center",
                gap: "5px",
              }}
            >
              <CountdownCell row={row} />
              {/* Pass row or any necessary props here */}
            </div>
          )
        },
      },
      {
        Header: () => (
          <div style={{ textAlign: "center" }}>Next Vital Check Time</div>
        ),
        accessor: "nextVitalCheckTime",
        Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>,
      },
      {
        Header: "Temperature",
        accessor: "temperature",
        Cell: ({ value }) => {
          //   const [temp, trend] = value.split(" ")
          //   const trendIcon = trend === "↑" ? UpArrow : DownArrow
          const trendIcon = UpArrow
          return (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                {value}
              </span>
              <img
                src={trendIcon}
                // alt={trend}
                alt="Icon"
                style={{ width: "15px", height: "20px" }}
              />
              {/* <span
                style={{ color: "#666", fontSize: "14px" }}
              >{`(${trend}98.5°F)`}</span> */}
              <span style={{ color: "#666", fontSize: "14px" }}>({value})</span>
              <span
                style={{ marginLeft: "auto", color: "#666", fontSize: "14px" }}
              >
                2:34 PM
              </span>
            </div>
          )
        },
      },
      {
        Header: "Blood Pressure",
        accessor: "bloodPressure",
        Cell: ({ value }) => {
          //   const [temp, trend] = value.split(" ")
          //   const trendIcon = trend === "↑" ? UpArrow : DownArrow
          const trendIcon = UpArrow
          return (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                {value}
              </span>
              <img
                src={trendIcon}
                // alt={trend}
                alt="Icon"
                style={{ width: "15px", height: "20px" }}
              />
              {/* <span
                style={{ color: "#666", fontSize: "14px" }}
              >{`(${trend}98.5°F)`}</span> */}
              <span style={{ color: "#666", fontSize: "14px" }}>({value})</span>
              <span
                style={{ marginLeft: "auto", color: "#666", fontSize: "14px" }}
              >
                2:34 PM
              </span>
            </div>
          )
        },
      },
      {
        Header: "SPO2",
        accessor: "spo2",
      },
      {
        Header: "Pulse Rate",
        accessor: "pulseRate",
        Cell: ({ value }) => {
          //   const [temp, trend] = value.split(" ")
          //   const trendIcon = trend === "↑" ? UpArrow : DownArrow
          const trendIcon = DownArrow
          return (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                {value}
              </span>
              <img
                src={trendIcon}
                // alt={trend}
                alt="Icon"
                style={{ width: "15px", height: "20px" }}
              />
              {/* <span
                style={{ color: "#666", fontSize: "14px" }}
              >{`(${trend}98.5°F)`}</span> */}
              <span style={{ color: "#666", fontSize: "14px" }}>({value})</span>
              <span
                style={{ marginLeft: "auto", color: "#666", fontSize: "14px" }}
              >
                2:34 PM
              </span>
            </div>
          )
        },
      },
      {
        Header: "Respiratory Rate",
        accessor: "respiratoryRate",
        Cell: ({ value }) => {
          //   const [temp, trend] = value.split(" ")
          //   const trendIcon = trend === "↑" ? UpArrow : DownArrow
          const trendIcon = UpArrow
          return (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                {value}
              </span>
              <img
                src={trendIcon}
                // alt={trend}
                alt="Icon"
                style={{ width: "15px", height: "20px" }}
              />
              {/* <span
                style={{ color: "#666", fontSize: "14px" }}
              >{`(${trend}98.5°F)`}</span> */}
              <span style={{ color: "#666", fontSize: "14px" }}>({value})</span>
              <span
                style={{ marginLeft: "auto", color: "#666", fontSize: "14px" }}
              >
                2:34 PM
              </span>
            </div>
          )
        },
      },
      {
        Header: "Blood Sugar",
        accessor: "bloodSugar",
        Cell: ({ value }) => {
          //   const [temp, trend] = value.split(" ")
          //   const trendIcon = trend === "↑" ? UpArrow : DownArrow
          const trendIcon = UpArrow
          return (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                {value}
              </span>
              <img
                src={trendIcon}
                // alt={trend}
                alt="Icon"
                style={{ width: "15px", height: "20px" }}
              />
              {/* <span
                style={{ color: "#666", fontSize: "14px" }}
              >{`(${trend}98.5°F)`}</span> */}
              <span style={{ color: "#666", fontSize: "14px" }}>({value})</span>
              <span
                style={{ marginLeft: "auto", color: "#666", fontSize: "14px" }}
              >
                2:34 PM
              </span>
            </div>
          )
        },
      },
      {
        Header: "Report SOS",
        accessor: "reportSos",
        Cell: ({ value }) => {
          return (
            <div
              style={{
                // display: "flex",
                textAlign: "center",
                gap: "5px",
              }}
            >
              <Link
                // to="/VideoCalling"
                to="/SosCall"
                style={{ textDecoration: "none" }}
              >
                <img
                  src={SOS}
                  alt="Icon"
                  style={{ width: "30px", height: "30x" }}
                />
              </Link>
            </div>
          )
        },
      },
      {
        Header: "EPrescription Report",
        accessor: "",
        Cell: ({ value }) => {
          return (
            <div
              style={{
                // display: "flex",
                textAlign: "center",
                gap: "5px",
              }}
            >
              <Link to="/EPrescriptionView" style={{ textDecoration: "none" }}>
                <img
                  src={Transfer}
                  alt="Icon"
                  style={{ width: "30px", height: "30x" }}
                />
              </Link>
            </div>
          )
        },
      },
      {
        Header: "General Examination",
        accessor: "",
        Cell: ({ value }) => {
          return (
            <div
              style={{
                gap: "5px",
                textAlign: "center",
              }}
            >
              <Link
                to="/GeneralExaminationForm"
                style={{ textDecoration: "none" }}
              >
                <img
                  src={GeneralExam}
                  alt="Icon"
                  style={{ width: "30px", height: "30px" }}
                />
              </Link>
            </div>
          )
        },
      },
      {
        Header: "Systematic Examination",
        accessor: "",
        Cell: ({ value }) => {
          return (
            <div
              style={{
                // display: "flex",
                textAlign: "center",
                gap: "5px",
              }}
            >
              <Link
                to="/SystematicExaminationForm"
                style={{ textDecoration: "none" }}
              >
                <img
                  src={SystematicExam}
                  alt="Icon"
                  style={{ width: "30px", height: "30x" }}
                />
              </Link>
            </div>
          )
        },
      },
      {
        Header: "Video Call",
        accessor: "callDoctor",
        Cell: ({ value }) => {
          return (
            <div
              style={{
                // display: "flex",
                textAlign: "center",
                gap: "5px",
              }}
            >
              <Link to="/SosCall" style={{ textDecoration: "none" }}>
                <img
                  src={CallDoctor}
                  alt="Icon"
                  style={{ width: "30px", height: "30x" }}
                />
              </Link>
            </div>
          )
        },
      },
      {
        Header: "Collaborate",
        accessor: "collaborate",
        Cell: ({ value }) => {
          return (
            <div
              style={{
                // display: "flex",
                textAlign: "center",
                gap: "5px",
              }}
            >
              <img
                src={Collaborate}
                alt="Icon"
                style={{ width: "30px", height: "30x" }}
              />
            </div>
          )
        },
      },
      {
        Header: "Transfer",
        accessor: "transfer",
        Cell: ({ value }) => {
          return (
            <div
              style={{
                // display: "flex",
                textAlign: "center",
                gap: "5px",
              }}
            >
              <img
                src={Transfer}
                alt="Icon"
                style={{ width: "30px", height: "30x" }}
              />
            </div>
          )
        },
      },
      {
        Header: "Report Mortality",
        accessor: "reportMortality",
        Cell: ({ value }) => {
          return (
            <div
              style={{
                // display: "flex",
                textAlign: "center",
                gap: "5px",
              }}
            >
              {/* <Link to="/EPrescriptionView" style={{ textDecoration: "none" }}> */}
              <img
                src={Mortality}
                alt="Icon"
                style={{ width: "30px", height: "30x" }}
              />
              {/* </Link> */}
            </div>
          )
        },
      },
    ],
    []
  )

  const data = [
    {
      bedNo: "102",
      uhidNo: "abc121",
      patientName: "Dev Kumar",
      gender: "Male",
      age: 28,
      admissionDate: "20/06/2024",
      doctorName: "Visak Kumar",
      sosIcon: "SOS Icon Here", // Replace with actual icon component if needed
      nextDoseTime: "3 PM",
      medTransfusionCounter: "00:00",
      nextVitalCheckTime: "2 PM",
      temperature: "98°F",
      bloodPressure: "110/78 mmHg",
      spo2: "98%",
      pulseRate: "56 BPM",
      respiratoryRate: "13 BPM",
      bloodSugar: "75 mg/dL",
      reportSos: "Action", // Replace with actual button/action
      callDoctor: "Action", // Replace with actual button/action
      collaborate: "Action", // Replace with actual button/action
      transfer: "Action", // Replace with actual button/action
      reportMortality: "Action", // Replace with actual button/action
    },
    {
      bedNo: "103",
      uhidNo: "abc122",
      patientName: "Moti Lal",
      gender: "Male",
      age: 28,
      admissionDate: "20/06/2024",
      doctorName: "Visak Kumar",
      sosIcon: "SOS Icon Here", // Replace with actual icon component if needed
      nextDoseTime: "3 PM",
      medTransfusionCounter: "00:00",
      nextVitalCheckTime: "2 PM",
      temperature: "98°F",
      bloodPressure: "110/78 mmHg",
      spo2: "98%",
      pulseRate: "56 BPM",
      respiratoryRate: "13 BPM",
      bloodSugar: "75 mg/dL",
      reportSos: "Action", // Replace with actual button/action
      callDoctor: "Action", // Replace with actual button/action
      collaborate: "Action", // Replace with actual button/action
      transfer: "Action", // Replace with actual button/action
      reportMortality: "Action", // Replace with actual button/action
    },
    {
      bedNo: "104",
      uhidNo: "abc123",
      patientName: "Arman Khan",
      gender: "Male",
      age: 28,
      admissionDate: "20/06/2024",
      doctorName: "Visak Kumar",
      sosIcon: "SOS Icon Here", // Replace with actual icon component if needed
      nextDoseTime: "3 PM",
      medTransfusionCounter: "00:00",
      nextVitalCheckTime: "2 PM",
      temperature: "98°F",
      bloodPressure: "110/78 mmHg",
      spo2: "98%",
      pulseRate: "56 BPM",
      respiratoryRate: "13 BPM",
      bloodSugar: "75 mg/dL",
      reportSos: "Action", // Replace with actual button/action
      callDoctor: "Action", // Replace with actual button/action
      collaborate: "Action", // Replace with actual button/action
      transfer: "Action", // Replace with actual button/action
      reportMortality: "Action", // Replace with actual button/action
    },
    {
      bedNo: "105",
      uhidNo: "abc124",
      patientName: "Mohan Kumar",
      gender: "Male",
      age: 28,
      admissionDate: "20/06/2024",
      doctorName: "Visak Kumar",
      sosIcon: "SOS Icon Here",
      nextDoseTime: "3 PM",
      medTransfusionCounter: "00:00",
      nextVitalCheckTime: "2 PM",
      temperature: "98°F",
      bloodPressure: "110/78 mmHg",
      spo2: "98%",
      pulseRate: "56 BPM",
      respiratoryRate: "13 BPM",
      bloodSugar: "75 mg/dL",
      reportSos: "Action",
      callDoctor: "Action",
      collaborate: "Action",
      transfer: "Action",
      reportMortality: "Action",
    },
    {
      bedNo: "102",
      uhidNo: "abc121",
      patientName: "Dev Kumar",
      gender: "Male",
      age: 28,
      admissionDate: "20/06/2024",
      doctorName: "Visak Kumar",
      sosIcon: "SOS Icon Here", // Replace with actual icon component if needed
      nextDoseTime: "3 PM",
      medTransfusionCounter: "00:00",
      nextVitalCheckTime: "2 PM",
      temperature: "98°F",
      bloodPressure: "110/78 mmHg",
      spo2: "98%",
      pulseRate: "56 BPM",
      respiratoryRate: "13 BPM",
      bloodSugar: "75 mg/dL",
      reportSos: "Action", // Replace with actual button/action
      callDoctor: "Action", // Replace with actual button/action
      collaborate: "Action", // Replace with actual button/action
      transfer: "Action", // Replace with actual button/action
      reportMortality: "Action", // Replace with actual button/action
    },
    {
      bedNo: "103",
      uhidNo: "abc122",
      patientName: "Moti Lal",
      gender: "Male",
      age: 28,
      admissionDate: "20/06/2024",
      doctorName: "Visak Kumar",
      sosIcon: "SOS Icon Here", // Replace with actual icon component if needed
      nextDoseTime: "3 PM",
      medTransfusionCounter: "00:00",
      nextVitalCheckTime: "2 PM",
      temperature: "98°F",
      bloodPressure: "110/78 mmHg",
      spo2: "98%",
      pulseRate: "56 BPM",
      respiratoryRate: "13 BPM",
      bloodSugar: "75 mg/dL",
      reportSos: "Action", // Replace with actual button/action
      callDoctor: "Action", // Replace with actual button/action
      collaborate: "Action", // Replace with actual button/action
      transfer: "Action", // Replace with actual button/action
      reportMortality: "Action", // Replace with actual button/action
    },
    {
      bedNo: "104",
      uhidNo: "abc123",
      patientName: "Arman Khan",
      gender: "Male",
      age: 28,
      admissionDate: "20/06/2024",
      doctorName: "Visak Kumar",
      sosIcon: "SOS Icon Here", // Replace with actual icon component if needed
      nextDoseTime: "3 PM",
      medTransfusionCounter: "00:00",
      nextVitalCheckTime: "2 PM",
      temperature: "98°F",
      bloodPressure: "110/78 mmHg",
      spo2: "98%",
      pulseRate: "56 BPM",
      respiratoryRate: "13 BPM",
      bloodSugar: "75 mg/dL",
      reportSos: "Action", // Replace with actual button/action
      callDoctor: "Action", // Replace with actual button/action
      collaborate: "Action", // Replace with actual button/action
      transfer: "Action", // Replace with actual button/action
      reportMortality: "Action", // Replace with actual button/action
    },
    {
      bedNo: "105",
      uhidNo: "abc124",
      patientName: "Mohan Kumar",
      gender: "Male",
      age: 28,
      admissionDate: "20/06/2024",
      doctorName: "Visak Kumar",
      sosIcon: "SOS Icon Here",
      nextDoseTime: "3 PM",
      medTransfusionCounter: "00:00",
      nextVitalCheckTime: "2 PM",
      temperature: "98°F",
      bloodPressure: "110/78 mmHg",
      spo2: "98%",
      pulseRate: "56 BPM",
      respiratoryRate: "13 BPM",
      bloodSugar: "75 mg/dL",
      reportSos: "Action",
      callDoctor: "Action",
      collaborate: "Action",
      transfer: "Action",
      reportMortality: "Action",
    },
  ]

  //meta title
  document.title = "NursingStation"

  const iframeRef = useRef(null)
  const [isMobile, setIsMobile] = useState(false)

  // hide sidebar
  useEffect(() => {
    tToggle()
  }, [])
  // this function is for changing sidebar state (it will hide side bars)
  function tToggle() {
    var body = document.body
    if (window.screen.width <= 998) {
      body.classList.add("sidebar-enable")
    } else {
      body.classList.add("vertical-collpsed")
      body.classList.add("sidebar-enable")
    }
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        {/* <Breadcrumbs
        // title="Nursing Health"
        //   breadcrumbItem="Nursing Health"
        /> */}
        {/* <Row>
          <Col
            md="6"
            lg="6"
            className="d-flex flex-column align-items-center mb-4"
          >
            <>
              <Card style={{ width: "100%" }}>
                <div
                  ref={iframeRef} // Reference to the div containing the iframe
                  style={{
                    position: "relative",
                    paddingTop: "56.25%", // 16:9 aspect ratio
                    width: "100%",
                    minHeight: "400px",
                  }}
                >
                  <iframe
                    src="https://livepush.io/embed/emmnW9tAl8m5CWK5"
                    allowFullScreen="1"
                    frameBorder="0"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  ></iframe>
                </div>
              </Card>
            </>
          </Col>
          <Col
            md="6"
            lg="6"
            // className="d-flex flex-column align-items-center mb-4"
            className={`d-flex flex-column align-items-center mb-4 ${
              isMobile ? "d-none" : ""
            }`}
          >
            {!isMobile && ( // Conditionally render iframe on larger screens only
              <>
                <Card style={{ width: "100%" }}>
                  <div
                    ref={iframeRef} // Reference to the div containing the iframe
                    style={{
                      position: "relative",
                      paddingTop: "56.25%", // 16:9 aspect ratio
                      width: "100%",
                      minHeight: "400px",
                    }}
                  >
                    <iframe
                      // src="https://livepush.io/embed/emmnW9tAl8m5CWK5"
                      src={
                        !isMobile
                          ? "https://livepush.io/embed/emmnW9tAl8m5CWK5"
                          : ""
                      }
                      allowFullScreen="1"
                      frameBorder="0"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                      }}
                    ></iframe>
                  </div>
                </Card>
              </>
            )}
          </Col>
          <Col lg="6" md="6">
            <Caller />
          </Col>
        </Row> */}
        <Card style={{ marginTop: "0px" }}>
          <CardBody>
            <TableContainer
              columns={columns}
              data={data}
              customPageSize={20}
              classNameHeader="dark"
              isNextPrvButtonsEnable={true}
              isGlobalFilter={true}
              isAddOptions={false}
              //   isJobListGlobalFilter
              handleCustomerClick
              //   className="custom-header-css"
              // rowColorStyle={row => ({
              //   backgroundColor: row.someCondition ? "#f0f0f0" : "#ffffff",
              // })}
            />
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

NursingStation.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default NursingStation
