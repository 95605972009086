import React, { useState, useEffect, useRef } from "react"
import { Card, Col, Container, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import RightBar from "./RightBar"
import LeftBar from "./LeftBar"
import SimpleBar from "simplebar-react"
import "simplebar-react/dist/simplebar.min.css"
import VideoConference from "./comp/Zego-cloud/VideoConference"
import Caller from "./comp/Zego-cloud/VideoCall/Caller"
import Receiver from "./comp/Zego-cloud/VideoCall/Receiver"
import FileUpload from "pages/NursingStation/FileUpload"

const Index = () => {
  //meta title
  document.title = "Video Calling"
  const formGroupStyle = {
    border: "2px solid  #e5e8eb",
    // border: "1px solid grey",
    borderRadius: 7,
    // padding: 10,
  }
  const [isWebView, setIsWebView] = useState(window.innerWidth >= 768)

  useEffect(() => {
    const handleResize = () => setIsWebView(window.innerWidth >= 768)
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  useEffect(() => {
    tToggle()
  }, [])
  // this function is for changing sidebar state (it will hide side bars)
  function tToggle() {
    var body = document.body
    if (window.screen.width <= 998) {
      body.classList.add("sidebar-enable")
    } else {
      body.classList.add("vertical-collpsed")
      body.classList.add("sidebar-enable")
    }
  }

  const iframeRef = useRef(null)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    // Check screen size on mount
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth < 768) // Adjust 768px as needed for your breakpoint
    }
    checkScreenSize()
    window.addEventListener("resize", checkScreenSize)

    return () => window.removeEventListener("resize", checkScreenSize)
  }, [])

  return (
    <React.Fragment>
      <div
        // className={isWebView ? "page-content" : ""}
        className="page-content"
        // className={window.innerWidth >= 768 ? "page-content" : ""}
        style={
          {
            // marginTop: window.innerWidth < 768 ? "200" : "", // 100 padding for mobile, 1rem for larger screens
            // padding: window.innerWidth < 768 ? "0" : "", // 0 padding for mobile, 1rem for larger screens
          }
        }
      >
        <Container fluid>
          {/* <Breadcrumbs title="" breadcrumbItem="" /> */}
          {/* <Col lg="6">
              <Caller />
            </Col> */}
          <Card>
            <Row>
              {/* <Col lg="12">
              <VideoConference />
            </Col> */}
              {/* <Col
              lg="6"
              md="6"
              // className="d-flex flex-column align-items-center mb-4"
              className={`d-flex flex-column align-items-center mb-4 ${
                isMobile ? "d-none" : ""
              }`}
            >
              {!isMobile && ( // Conditionally render iframe on larger screens only
                <>
                  <Card style={{ width: "100%" }}>
                    <div
                      ref={iframeRef} // Reference to the div containing the iframe
                      style={{
                        position: "relative",
                        paddingTop: "56.25%", // 16:9 aspect ratio
                        width: "100%",
                        minHeight: "400px",
                      }}
                    >
                      <iframe
                        // src="https://livepush.io/embed/emmnW9tAl8m5CWK5"
                        src={
                          !isMobile
                            ? "https://livepush.io/embed/emmnW9tAl8m5CWK5"
                            : ""
                        }
                        allowFullScreen="1"
                        frameBorder="0"
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                        }}
                      ></iframe>
                    </div>
                  </Card>
                </>
              )}
            </Col> */}
              <Col
                md="6"
                lg="6"
                className="d-flex flex-column align-items-center mb- mt-4"
              >
                <>
                  <h3
                    // className="text-danger"
                    style={{
                      color: "red",
                      // fontSize: window.innerWidth < 768 ? 18 : 24,
                    }}
                  >
                    GoPro Live (NBSU Center)
                  </h3>
                  <Card style={{ width: "100%" }}>
                    <div
                      ref={iframeRef} // Reference to the div containing the iframe
                      style={{
                        position: "relative",
                        paddingTop: "56.25%", // 16:9 aspect ratio
                        width: "100%",
                        minHeight: "400px",
                      }}
                    >
                      <iframe
                        src="https://livepush.io/embed/emmnW9tAl8m5CWK5"
                        allowFullScreen="1"
                        frameBorder="0"
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                        }}
                      ></iframe>
                    </div>
                  </Card>
                </>
              </Col>
              <Col lg="6" md="6">
                <Receiver />
              </Col>
              {/* <Col lg="6">
              <iframe
                src="https://meet.google.com/vof-pnug-wxk"
                height="500"
                width="500"
                allow="camera;microphone"
              ></iframe>
            </Col> */}
              {/* <Col
              md="6"
              // className="d-flex flex-column align-items-center mb-4"
              className={`d-flex flex-column align-items-center mb-4 ${
                isMobile ? "d-none" : ""
              }`}
            >
              {!isMobile && ( // Conditionally render iframe on larger screens only
                <>
                  <Card style={{ width: "100%" }}>
                    <div
                      ref={iframeRef} // Reference to the div containing the iframe
                      style={{
                        position: "relative",
                        paddingTop: "56.25%", // 16:9 aspect ratio
                        width: "100%",
                      }}
                    >
                      <iframe
                        // src="https://livepush.io/embed/emmnW9tAl8m5CWK5"
                        src={
                          !isMobile
                            ? "https://livepush.io/embed/emmnW9tAl8m5CWK5"
                            : ""
                        }
                        allowFullScreen="1"
                        frameBorder="0"
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                        }}
                      ></iframe>
                    </div>
                  </Card>
                </>
              )}
            </Col> */}
            </Row>
          </Card>

          <Row style={{}}>
            <Col lg={8}>
              <LeftBar />
            </Col>
            <Col lg={4}>
              {/* <SimpleBar style={{ maxHeight: "115vh", overflowY: "auto" }}>
                <RightBar />
              </SimpleBar> */}
              <div
                style={{
                  maxHeight: "115vh",
                  overflowY: "auto",
                  // padding: "10px",
                  // border: "1px solid #ccc",
                }}
              >
                <RightBar />
              </div>
            </Col>
          </Row>
          {/* <Row>
            <Col lg="8">
              <FileUpload />
            </Col>
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Index
