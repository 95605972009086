import React, { useState } from "react"
import {
  Container,
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import NewbornCaseRecordHeader from "./NewbornCaseRecordHeader"

const generalConditions = ["Alert", "Lethargic", "Comatose"]

const colorOptions = ["Pink", "Pale", "Central Cyanosis", "Peripheral Cyanosis"]
const cryOptions = ["Absent", "Feeble", "Normal", "High Pitch"]
const toneOptions = ["Limp", "Active", "Increase Tone"]
const convulsionsOptions = ["Present on Admission", "Past History", "No"]
const suckingOptions = ["Good", "Poor", "No Sucking"]
const attachmentOptions = ["Well attached", "Poorly attached", "Not attached"]
const bleedingOptions = ["Yes", "No"]
const jaundiceOptions = ["Yes", "No"]
const umbilicusOptions = ["Red", "Discharge", "Normal"]
const skinPustulesOptions = ["Yes < 10", "Yes > 10", "Abscess", "Congenital"]
const malformationOptions = ["Option 1", "Option 2", "Option 3"]

const GeneralExaminationForm = () => {
  const [selectedColor, setSelectedColor] = useState("")
  const [selectedCry, setSelectedCry] = useState("")
  const [selectedTone, setSelectedTone] = useState("")
  const [selectedConvulsions, setSelectedConvulsions] = useState("")
  const [selectedSucking, setSelectedSucking] = useState("")
  const [selectedAttachment, setSelectedAttachment] = useState("")
  const [selectedBleeding, setSelectedBleeding] = useState("")
  const [selectedJaundice, setSelectedJaundice] = useState("")
  const [selectedUmbilicus, setSelectedUmbilicus] = useState("")
  const [selectedSkinPustules, setSelectedSkinPustules] = useState("")
  const [selectedMalformation, setSelectedMalformation] = useState("")

  const toggleOption = setter => option => setter(option)

  const InputandOptionBorder = {
    border: "1px solid #ced4da",
    padding: "7px",
    borderRadius: 10,
  }

  return (
    <div className="page-content">
      <Container className="mt-">
        <Card className="mb-3">
          <CardHeader tag="h4" className="text-dark">
            General Examination (Newborn)
          </CardHeader>
          <CardBody>
            <Row>
              <Col lg="12">
                <NewbornCaseRecordHeader />
              </Col>
              <Col lg="12">
                <Form>
                  <Row form>
                    <Col md={6}>
                      {/* <FormGroup>
                        <Label for="generalCondition">General Condition</Label>
                        <div>
                          <Button
                            color="secondary"
                            outline
                            className="mr-2 mb-2"
                          >
                            Alert
                          </Button>
                          <Button
                            color="secondary"
                            outline
                            className="mr-2 mb-2"
                          >
                            Lethargic
                          </Button>
                          <Button color="secondary" outline className="mb-2">
                            Comatose
                          </Button>
                        </div>
                      </FormGroup> */}
                      <FormGroup>
                        <Label for="generalCondition">General Condition</Label>
                        <Row className="" style={InputandOptionBorder}>
                          {generalConditions.map(color => (
                            <Col key={color} xs="auto">
                              <Button
                                size="sm"
                                color={
                                  selectedColor === color ? "primary" : "light"
                                }
                                onClick={() =>
                                  toggleOption(setSelectedColor)(color)
                                }
                                className="m-1"
                              >
                                {color}
                              </Button>
                            </Col>
                          ))}
                        </Row>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="mt-2">
                        <Label for="temperature">Temperature (°F)</Label>
                        <Input
                          type="text"
                          id="temperature"
                          placeholder="Please Enter Temperature"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="heartRate">Heart Rate (/min)</Label>
                        <Input
                          type="text"
                          id="heartRate"
                          placeholder="Please Enter Heart Rate"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="apnea">Apnea</Label>
                        <div style={InputandOptionBorder}>
                          <Input
                            type="radio"
                            id="apneaYes"
                            name="apnea"
                            value="Yes"
                            className="mr-2 mx-2"
                          />{" "}
                          Yes
                          <Input
                            type="radio"
                            id="apneaNo"
                            name="apnea"
                            value="No"
                            className="ml-4 mr-2 mx-2"
                          />{" "}
                          No
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="rr">RR (/min) (Between 12-16)</Label>
                        <Input
                          type="text"
                          id="rr"
                          placeholder="Please Enter RR"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="bp">B.P. (mm/Hg)</Label>
                        <Row>
                          <Col md={6}>
                            <Input
                              type="text"
                              id="bpSystolic"
                              placeholder="Enter systolic"
                            />
                          </Col>
                          <Col md={6}>
                            <Input
                              type="text"
                              id="bpDiastolic"
                              placeholder="Enter diastolic"
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="grunting">Grunting</Label>
                        <div style={InputandOptionBorder}>
                          <Input
                            type="radio"
                            id="gruntingYes"
                            name="grunting"
                            value="Yes"
                            className="mr-2 mx-2"
                          />{" "}
                          Yes
                          <Input
                            type="radio"
                            id="gruntingNo"
                            name="grunting"
                            value="No"
                            className="ml-4 mr-2 mx-2"
                          />{" "}
                          No
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="chestIndrawing">Chest Indrawing:</Label>
                        <div style={InputandOptionBorder}>
                          <Input
                            type="radio"
                            id="chestIndrawingYes"
                            name="chestIndrawing"
                            value="Yes"
                            className="mr-2 mx-2"
                          />{" "}
                          Yes
                          <Input
                            type="radio"
                            id="chestIndrawingNo"
                            name="chestIndrawing"
                            value="No"
                            className="ml-4 mr-2 mx-2"
                          />{" "}
                          No
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="headCircumference">
                          Head Circumference (cm):
                        </Label>
                        <Input
                          type="text"
                          id="headCircumference"
                          placeholder="Please Enter Head Circumference"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="length">Length (cm):</Label>
                        <Input
                          type="text"
                          id="length"
                          placeholder="Please Enter Length"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="color">Color</Label>
                        <Row className="g-1" style={InputandOptionBorder}>
                          {colorOptions.map(color => (
                            <Col key={color} xs="auto">
                              <Button
                                size="sm"
                                color={
                                  selectedColor === color ? "primary" : "light"
                                }
                                onClick={() =>
                                  toggleOption(setSelectedColor)(color)
                                }
                                className="m-1"
                              >
                                {color}
                              </Button>
                            </Col>
                          ))}
                        </Row>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="crt">CRT &gt; 3 secs</Label>
                        <div style={InputandOptionBorder}>
                          <Input
                            type="radio"
                            id="crtYes"
                            name="crt"
                            value="Yes"
                            className="mr-2 mx-2"
                          />{" "}
                          Yes
                          <Input
                            type="radio"
                            id="crtNo"
                            name="crt"
                            value="No"
                            className="ml-4 mr-2 mx-2"
                          />{" "}
                          No
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="skinPinch">Skin pinch &gt; 2 secs</Label>
                        <div style={InputandOptionBorder}>
                          <Input
                            type="radio"
                            id="skinPinchYes"
                            name="skinPinch"
                            value="Yes"
                            className="mr-2 mx-2"
                          />{" "}
                          Yes
                          <Input
                            type="radio"
                            id="skinPinchNo"
                            name="skinPinch"
                            value="No"
                            className="ml-4 mr-2 mx-2"
                          />{" "}
                          No
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="meconiumCord">Meconium Stained Cord</Label>
                        <div style={InputandOptionBorder}>
                          <Input
                            type="radio"
                            id="meconiumCordYes"
                            name="meconiumCord"
                            value="Yes"
                            className="mr-2 mx-2"
                          />{" "}
                          Yes
                          <Input
                            type="radio"
                            id="meconiumCordNo"
                            name="meconiumCord"
                            value="No"
                            className="ml-4 mr-2 mx-2"
                          />{" "}
                          No
                        </div>
                      </FormGroup>
                    </Col>
                    {/* 
                    <Col md={6}>
                      <FormGroup>
                        <Label for="skinPustules">Skin Pustules</Label>
                        <Row className="g-1" style={InputandOptionBorder}>
                          {skinPustulesOptions.map(pustule => (
                            <Col key={pustule} xs="auto">
                              <Button
                                size="sm"
                                color={
                                  selectedSkinPustules === pustule
                                    ? "primary"
                                    : "light"
                                }
                                onClick={() =>
                                  toggleOption(setSelectedSkinPustules)(pustule)
                                }
                                className="m-1"
                              >
                                {pustule}
                              </Button>
                            </Col>
                          ))}
                        </Row>
                      </FormGroup>
                    </Col> */}
                  </Row>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="malformation">Cry</Label>
                        <Row className="g-1" style={InputandOptionBorder}>
                          {cryOptions.map(malformation => (
                            <Col key={malformation} xs="auto">
                              <Button
                                size="sm"
                                color={
                                  selectedCry === malformation
                                    ? "primary"
                                    : "light"
                                }
                                onClick={() =>
                                  toggleOption(setSelectedCry)(malformation)
                                }
                                className="m-1"
                              >
                                {malformation}
                              </Button>
                            </Col>
                          ))}
                        </Row>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="malformation">Tone</Label>
                        <Row className="g-1" style={InputandOptionBorder}>
                          {toneOptions.map(malformation => (
                            <Col key={malformation} xs="auto">
                              <Button
                                size="sm"
                                color={
                                  selectedTone === malformation
                                    ? "primary"
                                    : "light"
                                }
                                onClick={() =>
                                  toggleOption(setSelectedTone)(malformation)
                                }
                                className="m-1"
                              >
                                {malformation}
                              </Button>
                            </Col>
                          ))}
                        </Row>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="malformation">Convulsions</Label>
                        <Row className="g-1" style={InputandOptionBorder}>
                          {convulsionsOptions.map(malformation => (
                            <Col key={malformation} xs="auto">
                              <Button
                                size="sm"
                                color={
                                  selectedConvulsions === malformation
                                    ? "primary"
                                    : "light"
                                }
                                onClick={() =>
                                  toggleOption(setSelectedConvulsions)(
                                    malformation
                                  )
                                }
                                className="m-1"
                              >
                                {malformation}
                              </Button>
                            </Col>
                          ))}
                        </Row>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="malformation">Taking Breast Feed</Label>
                        <Row className="g-1" style={InputandOptionBorder}>
                          {bleedingOptions.map(malformation => (
                            <Col key={malformation} xs="auto">
                              <Button
                                size="sm"
                                color={
                                  selectedBleeding === malformation
                                    ? "primary"
                                    : "light"
                                }
                                onClick={() =>
                                  toggleOption(setSelectedBleeding)(
                                    malformation
                                  )
                                }
                                className="m-1"
                              >
                                {malformation}
                              </Button>
                            </Col>
                          ))}
                        </Row>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="malformation">Sucking </Label>
                        <Row className="g-1" style={InputandOptionBorder}>
                          {suckingOptions.map(malformation => (
                            <Col key={malformation} xs="auto">
                              <Button
                                size="sm"
                                color={
                                  selectedSucking === malformation
                                    ? "primary"
                                    : "light"
                                }
                                onClick={() =>
                                  toggleOption(setSelectedSucking)(malformation)
                                }
                                className="m-1"
                              >
                                {malformation}
                              </Button>
                            </Col>
                          ))}
                        </Row>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="malformation">Attachment</Label>
                        <Row className="g-1" style={InputandOptionBorder}>
                          {attachmentOptions.map(malformation => (
                            <Col key={malformation} xs="auto">
                              <Button
                                size="sm"
                                color={
                                  selectedAttachment === malformation
                                    ? "primary"
                                    : "light"
                                }
                                onClick={() =>
                                  toggleOption(setSelectedAttachment)(
                                    malformation
                                  )
                                }
                                className="m-1"
                              >
                                {malformation}
                              </Button>
                            </Col>
                          ))}
                        </Row>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="bleeding">Bleeding</Label>
                        <div style={InputandOptionBorder}>
                          <Input
                            type="radio"
                            id="bleedingYes"
                            name="bleeding"
                            value="Yes"
                            className="mr-2 mx-2"
                          />{" "}
                          Yes
                          <Input
                            type="radio"
                            id="bleedingNo"
                            name="bleeding"
                            value="No"
                            className="ml-4 mr-2 mx-2"
                          />{" "}
                          No
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="jaundice">Jaundice</Label>
                        <div style={InputandOptionBorder}>
                          <Input
                            type="radio"
                            id="jaundiceYes"
                            name="jaundice"
                            value="Yes"
                            className="mr-2 mx-2"
                          />{" "}
                          Yes
                          <Input
                            type="radio"
                            id="jaundiceNo"
                            name="jaundice"
                            value="No"
                            className="ml-4 mr-2 mx-2"
                          />{" "}
                          No
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="skinPinch">UbiCulas</Label>
                        <Row className="g-1" style={InputandOptionBorder}>
                          {umbilicusOptions.map(pustule => (
                            <Col key={pustule} xs="auto">
                              <Button
                                size="sm"
                                color={
                                  selectedUmbilicus === pustule
                                    ? "primary"
                                    : "light"
                                }
                                onClick={() =>
                                  toggleOption(setSelectedUmbilicus)(pustule)
                                }
                                className="m-1"
                              >
                                {pustule}
                              </Button>
                            </Col>
                          ))}
                        </Row>
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup>
                        <Label for="skinPustules">Skin Pustules</Label>
                        <Row className="g-1" style={InputandOptionBorder}>
                          {skinPustulesOptions.map(pustule => (
                            <Col key={pustule} xs="auto">
                              <Button
                                size="sm"
                                color={
                                  selectedSkinPustules === pustule
                                    ? "primary"
                                    : "light"
                                }
                                onClick={() =>
                                  toggleOption(setSelectedSkinPustules)(pustule)
                                }
                                className="m-1"
                              >
                                {pustule}
                              </Button>
                            </Col>
                          ))}
                        </Row>
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="attachment">Attachment</Label>
                        <div style={InputandOptionBorder}>
                          <Input
                            type="radio"
                            id="attachmentGood"
                            name="attachment"
                            value="Well attached"
                            className="mr-2 mx-2"
                          />{" "}
                          Well attached
                          <Input
                            type="radio"
                            id="attachmentPoor"
                            name="attachment"
                            value="Poorly attached"
                            className="ml-4 mr-2 mx-2"
                          />{" "}
                          Poorly attached
                          <Input
                            type="radio"
                            id="attachmentNotAttached"
                            name="attachment"
                            value="Not attached"
                            className="ml-4 mr-2 mx-2"
                          />{" "}
                          Not attached
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="sucking">Sucking</Label>
                        <div style={InputandOptionBorder}>
                          <Input
                            type="radio"
                            id="suckingGood"
                            name="sucking"
                            value="Good"
                            className="mr-2 mx-2"
                          />{" "}
                          Good
                          <Input
                            type="radio"
                            id="suckingPoor"
                            name="sucking"
                            value="Poor"
                            className="ml-4 mr-2 mx-2"
                          />{" "}
                          Poor
                          <Input
                            type="radio"
                            id="suckingNone"
                            name="sucking"
                            value="No Sucking"
                            className="ml-4 mr-2 mx-2"
                          />{" "}
                          No Sucking
                        </div>
                      </FormGroup>
                    </Col>
                  </Row> */}
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="bulgingFontanel">
                          Bulging Anterior Fontanel
                        </Label>
                        <div style={InputandOptionBorder}>
                          <Input
                            type="radio"
                            id="bulgingFontanelYes"
                            name="bulgingFontanel"
                            value="Yes"
                            className="mr-2 mx-2"
                          />{" "}
                          Yes
                          <Input
                            type="radio"
                            id="bulgingFontanelNo"
                            name="bulgingFontanel"
                            value="No"
                            className="ml-4 mr-2 mx-2"
                          />{" "}
                          No
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="malformation">Malformation</Label>
                        <Input type="select" id="malformation">
                          <option>Select Malformation</option>
                          <option>Option 1</option>
                          <option>Option 2</option>
                          <option>Option 3</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="bloodSugar">Blood Sugar</Label>
                        <Input
                          type="text"
                          id="bloodSugar"
                          placeholder="Please Enter Blood Sugar"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="oxygenSaturation">
                          Oxygen Saturation (70 or above)
                        </Label>
                        <Input
                          type="text"
                          id="oxygenSaturation"
                          placeholder="Please Enter Oxygen Saturation"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="otherSignificantInfo">
                          Other Significant Information
                        </Label>
                        <Input
                          type="textarea"
                          id="otherSignificantInfo"
                          placeholder="Please Enter Other Info"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="text-center">
                    <Button color="primary" type="submit">
                      Submit
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default GeneralExaminationForm

// import React, { useState } from "react"
// import {
//   Container,
//   Card,
//   CardBody,
//   CardHeader,
//   Row,
//   Col,
//   Form,
//   FormGroup,
//   Label,
//   Input,
//   Button,
//   FormText,
// } from "reactstrap"
// import "bootstrap/dist/css/bootstrap.min.css"
// import NewbornCaseRecordHeader from "./NewbornCaseRecordHeader"

// const colorOptions = ["Pink", "Pale", "Central Cyanosis", "Peripheral Cyanosis"]
// const cryOptions = ["Absent", "Feeble", "Normal", "High Pitch"]
// const toneOptions = ["Limp", "Active", "Increase Tone"]
// const convulsionsOptions = ["Present on Admission", "Past History", "No"]
// const suckingOptions = ["Good", "Poor", "No Sucking"]
// const attachmentOptions = ["Well attached", "Poorly attached", "Not attached"]
// const bleedingOptions = ["Yes", "No"]
// const jaundiceOptions = ["Yes", "No"]
// const umbilicusOptions = ["Red", "Discharge", "Normal"]
// const skinPustulesOptions = ["Yes < 10", "Yes > 10", "Abscess", "Congenital"]
// const malformationOptions = ["Option 1", "Option 2", "Option 3"]

// const GeneralExaminationForm = () => {
//   const [selectedColor, setSelectedColor] = useState("")
//   const [selectedCry, setSelectedCry] = useState("")
//   const [selectedTone, setSelectedTone] = useState("")
//   const [selectedConvulsions, setSelectedConvulsions] = useState("")
//   const [selectedSucking, setSelectedSucking] = useState("")
//   const [selectedAttachment, setSelectedAttachment] = useState("")
//   const [selectedBleeding, setSelectedBleeding] = useState("")
//   const [selectedJaundice, setSelectedJaundice] = useState("")
//   const [selectedUmbilicus, setSelectedUmbilicus] = useState("")
//   const [selectedSkinPustules, setSelectedSkinPustules] = useState("")
//   const [selectedMalformation, setSelectedMalformation] = useState("")

//   const toggleOption = setter => option => setter(option)

//   const InputandOptionBorder = {
//     border: "1px solid #ced4da",
//     padding: "5px",
//     borderRadius: 15,
//   }
//   return (
//     <div className="page-content">
//       <Container className="mt-">
//         <Card className="mb-3">
//           <CardHeader tag="h4" className="text-dark">
//             General Examination (Newborn)
//           </CardHeader>
//           <CardBody>
//             <Row>
//               <Col lg="12">
//                 <NewbornCaseRecordHeader />
//               </Col>
//               <Col lg="12">
//                 <Form>
//                   <Row form>
//                     <Col md={6}>
//                       <FormGroup>
//                         <Label for="generalCondition">General Condition</Label>
//                         <div>
//                           <Button
//                             color="secondary"
//                             outline
//                             className="mr-2 mb-2"
//                           >
//                             Alert
//                           </Button>
//                           <Button
//                             color="secondary"
//                             outline
//                             className="mr-2 mb-2"
//                           >
//                             Lethargic
//                           </Button>
//                           <Button color="secondary" outline className="mb-2">
//                             Comatose
//                           </Button>
//                         </div>
//                       </FormGroup>
//                     </Col>
//                     <Col md={6}>
//                       <FormGroup>
//                         <Label for="temperature">Temperature (°F)</Label>
//                         <Input
//                           type="text"
//                           id="temperature"
//                           placeholder="Please Enter Temperature"
//                         />
//                       </FormGroup>
//                     </Col>
//                   </Row>

//                   <Row form>
//                     <Col md={6}>
//                       <FormGroup>
//                         <Label for="heartRate">Heart Rate (/min)</Label>
//                         <Input
//                           type="text"
//                           id="heartRate"
//                           placeholder="Please Enter Heart Rate"
//                         />
//                       </FormGroup>
//                     </Col>
//                     <Col md={6}>
//                       <FormGroup>
//                         <Label for="apnea">Apnea</Label>
//                         <div>
//                           <Input
//                             type="radio"
//                             id="apneaYes"
//                             name="apnea"
//                             value="Yes"
//                             className="mr-2"
//                           />{" "}
//                           Yes
//                           <Input
//                             type="radio"
//                             id="apneaNo"
//                             name="apnea"
//                             value="No"
//                             className="ml-4 mr-2"
//                           />{" "}
//                           No
//                         </div>
//                       </FormGroup>
//                     </Col>
//                   </Row>

//                   <Row form>
//                     <Col md={6}>
//                       <FormGroup>
//                         <Label for="rr">RR (/min) (Between 12-16)</Label>
//                         <Input
//                           type="text"
//                           id="rr"
//                           placeholder="Please Enter RR"
//                         />
//                       </FormGroup>
//                     </Col>
//                     <Col md={6}>
//                       <FormGroup>
//                         <Label for="bp">B.P. (mm/Hg)</Label>
//                         <Row>
//                           <Col md={6}>
//                             <Input
//                               type="text"
//                               id="bpSystolic"
//                               placeholder="Enter systolic"
//                             />
//                           </Col>
//                           <Col md={6}>
//                             <Input
//                               type="text"
//                               id="bpDiastolic"
//                               placeholder="Enter diastolic"
//                             />
//                           </Col>
//                         </Row>
//                       </FormGroup>
//                     </Col>
//                   </Row>

//                   <Row form>
//                     <Col md={6}>
//                       <FormGroup>
//                         <Label for="grunting">Grunting</Label>
//                         <div>
//                           <Input
//                             type="radio"
//                             id="gruntingYes"
//                             name="grunting"
//                             value="Yes"
//                             className="mr-2"
//                           />{" "}
//                           Yes
//                           <Input
//                             type="radio"
//                             id="gruntingNo"
//                             name="grunting"
//                             value="No"
//                             className="ml-4 mr-2"
//                           />{" "}
//                           No
//                         </div>
//                       </FormGroup>
//                     </Col>
//                     <Col md={6}>
//                       <FormGroup>
//                         <Label for="chestIndrawing">Chest Indrawing:</Label>
//                         <div>
//                           <Input
//                             type="radio"
//                             id="chestIndrawingYes"
//                             name="chestIndrawing"
//                             value="Yes"
//                             className="mr-2"
//                           />{" "}
//                           Yes
//                           <Input
//                             type="radio"
//                             id="chestIndrawingNo"
//                             name="chestIndrawing"
//                             value="No"
//                             className="ml-4 mr-2"
//                           />{" "}
//                           No
//                         </div>
//                       </FormGroup>
//                     </Col>
//                   </Row>

//                   <Row form>
//                     <Col md={6}>
//                       <FormGroup>
//                         <Label for="headCircumference">
//                           Head Circumference (cm):
//                         </Label>
//                         <Input
//                           type="text"
//                           id="headCircumference"
//                           placeholder="Please Enter Head Circumference"
//                         />
//                       </FormGroup>
//                     </Col>
//                     <Col md={6}>
//                       <FormGroup>
//                         <Label for="length">Length (cm):</Label>
//                         <Input
//                           type="text"
//                           id="length"
//                           placeholder="Please Enter Length"
//                         />
//                       </FormGroup>
//                     </Col>
//                   </Row>

//                   <Row form>
//                     <Col md={6}>
//                       <FormGroup>
//                         <Label for="color">Color</Label>
//                         <Row className="g-1" style={InputandOptionBorder}>
//                           {colorOptions.map(color => (
//                             <Col key={color} xs="auto">
//                               <Button
//                                 size="sm"
//                                 color={
//                                   selectedColor === color ? "primary" : "light"
//                                 }
//                                 onClick={() =>
//                                   toggleOption(setSelectedColor)(color)
//                                 }
//                                 className="m-1"
//                               >
//                                 {color}
//                               </Button>
//                             </Col>
//                           ))}
//                         </Row>
//                       </FormGroup>
//                     </Col>
//                     <Col md={6}>
//                       <FormGroup>
//                         <Label for="crt">CRT &gt; 3 secs</Label>
//                         <div>
//                           <Input
//                             type="radio"
//                             id="crtYes"
//                             name="crt"
//                             value="Yes"
//                             className="mr-2"
//                           />{" "}
//                           Yes
//                           <Input
//                             type="radio"
//                             id="crtNo"
//                             name="crt"
//                             value="No"
//                             className="ml-4 mr-2"
//                           />{" "}
//                           No
//                         </div>
//                       </FormGroup>
//                     </Col>
//                   </Row>

//                   <Row form>
//                     <Col md={6}>
//                       <FormGroup>
//                         <Label for="skinPinch">Skin pinch &gt; 2 secs</Label>
//                         <div>
//                           <Input
//                             type="radio"
//                             id="skinPinchYes"
//                             name="skinPinch"
//                             value="Yes"
//                             className="mr-2"
//                           />{" "}
//                           Yes
//                           <Input
//                             type="radio"
//                             id="skinPinchNo"
//                             name="skinPinch"
//                             value="No"
//                             className="ml-4 mr-2"
//                           />{" "}
//                           No
//                         </div>
//                       </FormGroup>
//                     </Col>
//                     <Col md={6}>
//                       <FormGroup>
//                         <Label for="meconiumStainedCord">
//                           Meconium Stained Cord
//                         </Label>
//                         <div>
//                           <Input
//                             type="radio"
//                             id="meconiumStainedCordYes"
//                             name="meconiumStainedCord"
//                             value="Yes"
//                             className="mr-2"
//                           />{" "}
//                           Yes
//                           <Input
//                             type="radio"
//                             id="meconiumStainedCordNo"
//                             name="meconiumStainedCord"
//                             value="No"
//                             className="ml-4 mr-2"
//                           />{" "}
//                           No
//                         </div>
//                       </FormGroup>
//                     </Col>
//                   </Row>

//                   <Row form>
//                     <Col md={6}>
//                       <FormGroup>
//                         <Label for="cry">Cry</Label>
//                         <div>
//                           <Button
//                             color="secondary"
//                             outline
//                             className="mr-2 mb-2"
//                           >
//                             Absent
//                           </Button>
//                           <Button
//                             color="secondary"
//                             outline
//                             className="mr-2 mb-2"
//                           >
//                             Feeble
//                           </Button>
//                           <Button
//                             color="secondary"
//                             outline
//                             className="mr-2 mb-2"
//                           >
//                             Normal
//                           </Button>
//                           <Button color="secondary" outline className="mb-2">
//                             High Pitch
//                           </Button>
//                         </div>
//                       </FormGroup>
//                     </Col>
//                     <Col md={6}>
//                       <FormGroup>
//                         <Label for="tone">Tone</Label>
//                         <div>
//                           <Button
//                             color="secondary"
//                             outline
//                             className="mr-2 mb-2"
//                           >
//                             Limp
//                           </Button>
//                           <Button
//                             color="secondary"
//                             outline
//                             className="mr-2 mb-2"
//                           >
//                             Active
//                           </Button>
//                           <Button
//                             color="secondary"
//                             outline
//                             className="mr-2 mb-2"
//                           >
//                             Increase Tone
//                           </Button>
//                         </div>
//                       </FormGroup>
//                     </Col>
//                   </Row>

//                   <Row form>
//                     <Col md={6}>
//                       <FormGroup>
//                         <Label for="convulsions">Convulsions</Label>
//                         <div>
//                           <Button
//                             color="secondary"
//                             outline
//                             className="mr-2 mb-2"
//                           >
//                             Present on Admission
//                           </Button>
//                           <Button
//                             color="secondary"
//                             outline
//                             className="mr-2 mb-2"
//                           >
//                             Past History
//                           </Button>
//                           <Button color="secondary" outline className="mb-2">
//                             No
//                           </Button>
//                         </div>
//                       </FormGroup>
//                     </Col>
//                     <Col md={6}>
//                       <FormGroup>
//                         <Label for="takingBreastFeeds">
//                           Taking Breast feeds
//                         </Label>
//                         <div>
//                           <Input
//                             type="radio"
//                             id="takingBreastFeedsYes"
//                             name="takingBreastFeeds"
//                             value="Yes"
//                             className="mr-2"
//                           />{" "}
//                           Yes
//                           <Input
//                             type="radio"
//                             id="takingBreastFeedsNo"
//                             name="takingBreastFeeds"
//                             value="No"
//                             className="ml-4 mr-2"
//                           />{" "}
//                           No
//                         </div>
//                       </FormGroup>
//                     </Col>
//                   </Row>

//                   <Row form>
//                     <Col md={6}>
//                       <FormGroup>
//                         <Label for="sucking">Sucking</Label>
//                         <div>
//                           <Button
//                             color="secondary"
//                             outline
//                             className="mr-2 mb-2"
//                           >
//                             Good
//                           </Button>
//                           <Button
//                             color="secondary"
//                             outline
//                             className="mr-2 mb-2"
//                           >
//                             Poor
//                           </Button>
//                           <Button color="secondary" outline className="mb-2">
//                             No Sucking
//                           </Button>
//                         </div>
//                       </FormGroup>
//                     </Col>
//                     <Col md={6}>
//                       <FormGroup>
//                         <Label for="attachment">Attachment</Label>
//                         <div>
//                           <Button
//                             color="secondary"
//                             outline
//                             className="mr-2 mb-2"
//                           >
//                             Well attached
//                           </Button>
//                           <Button
//                             color="secondary"
//                             outline
//                             className="mr-2 mb-2"
//                           >
//                             Poorly attached
//                           </Button>
//                           <Button color="secondary" outline className="mb-2">
//                             Not attached
//                           </Button>
//                         </div>
//                       </FormGroup>
//                     </Col>
//                   </Row>

//                   <Row form>
//                     <Col md={6}>
//                       <FormGroup>
//                         <Label for="bleeding">Bleeding</Label>
//                         <div>
//                           <Input
//                             type="radio"
//                             id="bleedingYes"
//                             name="bleeding"
//                             value="Yes"
//                             className="mr-2"
//                           />{" "}
//                           Yes
//                           <Input
//                             type="radio"
//                             id="bleedingNo"
//                             name="bleeding"
//                             value="No"
//                             className="ml-4 mr-2"
//                           />{" "}
//                           No
//                         </div>
//                       </FormGroup>
//                     </Col>
//                     <Col md={6}>
//                       <FormGroup>
//                         <Label for="jaundice">Jaundice</Label>
//                         <div>
//                           <Input
//                             type="radio"
//                             id="jaundiceYes"
//                             name="jaundice"
//                             value="Yes"
//                             className="mr-2"
//                           />{" "}
//                           Yes
//                           <Input
//                             type="radio"
//                             id="jaundiceNo"
//                             name="jaundice"
//                             value="No"
//                             className="ml-4 mr-2"
//                           />{" "}
//                           No
//                         </div>
//                       </FormGroup>
//                     </Col>
//                   </Row>

//                   <Row form>
//                     <Col md={6}>
//                       <FormGroup>
//                         <Label for="umbilicus">Umbilicus</Label>
//                         <div>
//                           <Button
//                             color="secondary"
//                             outline
//                             className="mr-2 mb-2"
//                           >
//                             Red
//                           </Button>
//                           <Button
//                             color="secondary"
//                             outline
//                             className="mr-2 mb-2"
//                           >
//                             Discharge
//                           </Button>
//                           <Button color="secondary" outline className="mb-2">
//                             Normal
//                           </Button>
//                         </div>
//                       </FormGroup>
//                     </Col>
//                     <Col md={6}>
//                       <FormGroup>
//                         <Label for="skinPustules">Skin Pustules</Label>
//                         <div>
//                           <Button
//                             color="secondary"
//                             outline
//                             className="mr-2 mb-2"
//                           >
//                             Yes &lt; 10
//                           </Button>
//                           <Button
//                             color="secondary"
//                             outline
//                             className="mr-2 mb-2"
//                           >
//                             Yes &gt; 10
//                           </Button>
//                           <Button
//                             color="secondary"
//                             outline
//                             className="mr-2 mb-2"
//                           >
//                             Abscess
//                           </Button>
//                           <Button color="secondary" outline className="mb-2">
//                             Congenital
//                           </Button>
//                         </div>
//                       </FormGroup>
//                     </Col>
//                   </Row>

//                   <Row form>
//                     <Col md={6}>
//                       <FormGroup>
//                         <Label for="bulgingFontanel">
//                           Bulging Anterior Fontanel
//                         </Label>
//                         <div>
//                           <Input
//                             type="radio"
//                             id="bulgingFontanelYes"
//                             name="bulgingFontanel"
//                             value="Yes"
//                             className="mr-2"
//                           />{" "}
//                           Yes
//                           <Input
//                             type="radio"
//                             id="bulgingFontanelNo"
//                             name="bulgingFontanel"
//                             value="No"
//                             className="ml-4 mr-2"
//                           />{" "}
//                           No
//                         </div>
//                       </FormGroup>
//                     </Col>
//                     <Col md={6}>
//                       <FormGroup>
//                         <Label for="malformation">Malformation</Label>
//                         <Input type="select" id="malformation">
//                           <option>Select Malformation</option>
//                           <option>Option 1</option>
//                           <option>Option 2</option>
//                           <option>Option 3</option>
//                         </Input>
//                       </FormGroup>
//                     </Col>
//                   </Row>

//                   <Row form>
//                     <Col md={6}>
//                       <FormGroup>
//                         <Label for="bloodSugar">Blood Sugar</Label>
//                         <Input
//                           type="text"
//                           id="bloodSugar"
//                           placeholder="Please Enter Blood Sugar"
//                         />
//                       </FormGroup>
//                     </Col>
//                     <Col md={6}>
//                       <FormGroup>
//                         <Label for="oxygenSaturation">
//                           Oxygen Saturation (70 or above)
//                         </Label>
//                         <Input
//                           type="text"
//                           id="oxygenSaturation"
//                           placeholder="Please Enter Oxygen Saturation"
//                         />
//                       </FormGroup>
//                     </Col>
//                   </Row>

//                   <Row form>
//                     <Col md={12}>
//                       <FormGroup>
//                         <Label for="otherSignificantInfo">
//                           Other Significant Information
//                         </Label>
//                         <Input
//                           type="textarea"
//                           id="otherSignificantInfo"
//                           placeholder="Please Enter Other Info"
//                         />
//                       </FormGroup>
//                     </Col>
//                   </Row>

//                   <Button color="primary" className="mt-3">
//                     Submit
//                   </Button>
//                 </Form>
//               </Col>
//             </Row>
//           </CardBody>
//         </Card>
//       </Container>
//     </div>
//   )
// }

// export default GeneralExaminationForm
