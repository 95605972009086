import React from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Badge,
  ListGroupItem,
  ListGroup,
  Button,
} from "reactstrap"
import { BiCircle, BiXCircle } from "react-icons/bi"
import logo from "../../assets/images/kcc_logo.png"
import SampleLogo from "../../assets/images/sample_logo.png"

import {
  Hospital,
  User,
  Calendar,
  Activity,
  Thermometer,
  Heart,
  Timer,
  Droplet,
  Pills,
  TestTube,
  Stethoscope,
  UtensilsCrossed,
  Yoga,
  UserCog,
  Monitor,
  BadgeAlert,
  Bed,
  Building2,
  UserCircle2,
  FileWarning,
} from "lucide-react"
const EPrescriptionView = () => {
  const medicines = [
    {
      name: "Tablet Dolo 650MG",
      schedule: {
        morning: true,
        afternoon: true,
        evening: false,
        night: false,
      },
      duration: "3 Days",
      takenWith: "Milk",
      // price: "₹98",
      // saving: "₹100",
    },
    {
      name: "Tablet Zantac 150MG",
      schedule: {
        morning: true,
        afternoon: false,
        evening: true,
        night: false,
      },
      duration: "3 Days",
      takenWith: "Warm Water",
      // price: "₹1634",
      // saving: "₹642",
    },
    {
      name: "Syrup Corex",
      schedule: {
        morning: false,
        afternoon: true,
        evening: true,
        night: true,
      },
      duration: "7 Days",
      takenWith: "Water",
      // price: "₹240",
      // saving: "₹120",
    },
  ]

  const renderSchedule = schedule => (
    <div className="schedule-indicators">
      <span className={`schedule-dot ${schedule.morning ? "active" : ""}`}>
        {schedule.morning ? (
          <BiCircle className="text-success" />
        ) : (
          <BiXCircle className="text-danger" />
        )}
        {/* <small>M</small> */}
      </span>
      <span className="schedule-separator">-</span>
      <span className={`schedule-dot ${schedule.afternoon ? "active" : ""}`}>
        {schedule.afternoon ? (
          <BiCircle className="text-success" />
        ) : (
          <BiXCircle className="text-danger" />
        )}
        {/* <small>A</small> */}
      </span>
      <span className="schedule-separator">-</span>
      <span className={`schedule-dot ${schedule.evening ? "active" : ""}`}>
        {schedule.evening ? (
          <BiCircle className="text-success" />
        ) : (
          <BiXCircle className="text-danger" />
        )}
        {/* <small>E</small> */}
      </span>
      <span className="schedule-separator">-</span>
      <span className={`schedule-dot ${schedule.night ? "active" : ""}`}>
        {schedule.night ? (
          <BiCircle className="text-success" />
        ) : (
          <BiXCircle className="text-danger" />
        )}
        {/* <small>N</small> */}
      </span>
    </div>
  )

  // const vitals = {
  //   Height: "5 Feet, 8 Inches",
  //   Weight: "65 Kgs",
  //   PulseRate: "98 BPM",
  //   Temperature: "100°F",
  //   RespiratoryRate: "98 breaths/min",
  //   SpO2: "98 mm Hg",
  // }

  const vitals = {
    Height: "5 Feet, 8 Inches",
    Weight: "65 Kgs",
    "Pulse Rate": "98 BPM",
    Temperature: "100°F",
    "Respiratory Rate": "98 breaths/min",
    SpO2: "98 mm Hg",
  }
  const diagnosticTests = [
    {
      type: "Pathology",
      tests: [
        { name: "Lipid Profile" },
        { name: "LFT" },
        { name: "KFT" },
        { name: "Urine Analysis" },
      ],
    },
    {
      type: "Radiology",
      tests: [{ name: "ECG" }],
    },
  ]

  const pricingInfo = [
    {
      label: "Estimated Price",
      value: "₹ 999",
    },
    {
      label: "Saving",
      value: "₹ 500",
    },
  ]

  return (
    <div
      className="page-content"
      style={{
        // fontFamily: '"Inter", sans-serif',
        // fontFamily: '"Inter", sans',
        // fontFamily: "'Open Sans', sans-serif",
        fontFamily: "'Roboto', sans-serif",
        // fontFamily: "'Roboto', serif",
        // fontFamily: "'Lato', sans-serif",
        fontSize: 18,
      }}
    >
      <Container className="py-4">
        <Card>
          <CardBody>
            {/* Logo Header */}
            {/* <div className="text-start mb-4">
              <img
                src={SampleLogo}
                alt="My Family Clinic"
                className="img-fluid"
                style={{ height: "50px" }}
              />
            </div> */}

            {/* Doctor and Patient Header */}
            {/* <hr className="text-dark" /> */}
            <div className="mb-4">
              <Card className="border-0 shadow-sm mb-4">
                <CardBody>
                  <div className="d-flex align-items-center justify-content-md-end mb-4">
                    <Calendar className="me-2" size={20} />
                    <p className="mb-1">DATE: 12-AUG-2021</p>
                  </div>
                  <Row>
                    <Col md={4} sm={4}>
                      <div className="d-flex align-items-center mb-2">
                        <User className="me-2" size={20} />
                        <h6 className="mb-0">DOCTOR</h6>
                      </div>
                      <h5 className="fw-bold mb-1 ">DR. ASHOO</h5>
                      <h6 className="text-uppercase mb-1">CARDIOLOGIST</h6>
                      <p>MBBS, MD</p>
                    </Col>
                    <Col md={4}>
                      <div className="text-center mb-4">
                        <img
                          // src="/api/placeholder/100/50"
                          // src="public\sample_logo.png"
                          src={SampleLogo}
                          alt="My Family Clinic"
                          className="img-fluid"
                          style={{ height: "60px" }}
                        />
                      </div>
                    </Col>
                    <Col md={4} className="text-md-end">
                      {/* <div className="d-flex align-items-center justify-content-md-end mb-2">
                        <Calendar className="me-2" size={20} />
                        <p className="mb-1">DATE: 12-AUG-2021</p>
                      </div> */}
                      <p className="mb-1">
                        <strong>PATIENT</strong>
                      </p>
                      <p className="mb-1">
                        <strong>ZAFRAN KHAN</strong>
                      </p>
                      <p className="mb-1">MALE, 25 YEARS, 9 MONTHS</p>
                      <p className="mb-1">9015687785</p>
                      <p className="mb-0">HPP NO: AZQ1234ASD</p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>

            {/* Vitals Section */}
            <Card
              className="border-0 shadow-sm mb-4"
              // style={{borderColor:""}}
            >
              <CardBody>
                <div className="d-flex align-items-center mb-3">
                  <Activity className="me-2" size={20} />
                  <h5 className="text-uppercase mb-0">VITALS</h5>
                </div>
                <Row className="g-3">
                  {Object.entries(vitals).map(([key, value], index) => (
                    <Col xs={6} sm={4} md={2} key={key}>
                      <div className="border rounded p-2 text-center h-100 bg-primary2 text-primary border-primary shadow-sm border-1">
                        {index === 0 && <Timer size={18} className="mb-1" />}
                        {index === 1 && <Activity size={18} className="mb-1" />}
                        {index === 2 && <Heart size={18} className="mb-1" />}
                        {index === 3 && (
                          <Thermometer size={18} className="mb-1" />
                        )}
                        {index === 4 && <Timer size={18} className="mb-1" />}
                        {index === 5 && <Droplet size={18} className="mb-1" />}
                        <small className="text-muted2 d-block text-white2 fs-6 mb-">
                          {key}
                        </small>
                        <strong>{value}</strong>
                      </div>
                    </Col>
                  ))}
                </Row>
              </CardBody>
            </Card>

            {/* <Card className="border-0 shadow-sm mb-4">
              <CardBody>
                <div className="mb-4">
                  <Row>
                    <Col md={6}>
                      <p className="mb-1">
                        <strong>SYMPTOMS:</strong>
                        <span className="mx-2 text-primary">
                          COUGH, CHEST PAIN
                        </span>
                      </p>
                    </Col>
                    <Col md={6}>
                      <p className="mb-1">
                        <strong>FINDINGS/DIAGNOSIS:</strong>
                        <span className="mx-2 text-primary">
                          CHEST CONGESTION, DENGUE
                        </span>
                      </p>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card> */}
            <Card className="border-0 shadow-sm mb-4">
              <CardBody>
                <div className="mb-4">
                  <Row>
                    <Col md={6}>
                      <p className="mb-1">
                        <strong className="fs-5">SYMPTOMS:</strong>
                        <div className="d-flex flex-wrap mt-2">
                          <Button
                            color="primary"
                            className="rounded-pill px-3 py-1 me-2 mb-2"
                          >
                            Fever
                          </Button>
                          <Button
                            color="primary"
                            className="rounded-pill px-3 py-1 mb-2"
                          >
                            Headaches
                          </Button>
                        </div>
                      </p>
                    </Col>
                    <Col md={6}>
                      <p className="mb-1">
                        <strong className="fs-5">FINDINGS/DIAGNOSIS:</strong>
                        <div className="d-flex flex-wrap mt-2">
                          <Button
                            color="primary"
                            className="rounded-pill px-3 py-1 me-2 mb-2"
                          >
                            Chikungunya
                          </Button>
                          <Button
                            color="primary"
                            className="rounded-pill px-3 py-1 mb-2"
                          >
                            Dengue
                          </Button>
                        </div>
                      </p>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
            {/* E-Prescription Section */}
            <Card className="border-0 shadow-sm mb-4">
              <CardBody>
                <div className="mb-4">
                  <h5 className="d-flex align-items-center">
                    <span className="me-3 fs-1">℞</span>
                    <strong> E-PRESCRIPTION</strong>
                  </h5>
                  <div className="text-end">
                    <Button className=" mx-2 mb-2" size="sm" color="primary">
                      Estimated Price - ₹ 999
                    </Button>
                    <Button size="sm" className="mx-2 mb-2" color="primary">
                      Saving - ₹ 500
                    </Button>
                  </div>
                  <div className="table-responsive">
                    <Table bordered className="mb-0">
                      <thead>
                        <tr>
                          <th>Medicine</th>
                          <th className="text-center">
                            [Morning/Afternoon/Evening/Night]
                          </th>
                          <th>Duration</th>
                          <th>Taken With</th>
                          {/* <th>Est. Price</th> */}
                          {/* <th>Saving</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {medicines.map((med, index) => (
                          <tr key={index}>
                            <td>{med.name}</td>
                            <td className="text-center">
                              {renderSchedule(med.schedule)}
                            </td>
                            <td>{med.duration}</td>
                            <td>{med.takenWith}</td>
                            {/* <td>{med.price}</td> */}
                            {/* <td>{med.saving}</td> */}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </CardBody>
            </Card>
            {/* Diagnostic Tests */}
            {/* <Card className="border-0 shadow-sm mb-4">
              <CardBody>
                <div className="mb-4">
                  <Row>
                    <Col md={8}>
                      <h5 className="mb-3">
                        <span className="me-2">🔬</span>
                        DIAGNOSTIC TESTS/INVESTIGATIONS
                      </h5>
                      <ul className="list-unstyled fs-7">
                        <li>
                          • PATHOLOGY:
                          <span className="mx-2 text-primary fs-7">
                            Lipid Profile, LFT, KFT, Urine Analysis
                          </span>
                        </li>
                        <li>
                          • RADIOLOGY:
                          <span className="mx-2 text-primary fs-7">ECG</span>
                        </li>
                      </ul>
                    </Col>
                    <Col md={4} className="text-end">
                      <div>Estimated Price - ₹ 1634</div>
                      <div>Saving - ₹ 642</div>
                      <div>Estimated Price - ₹ 240</div>
                      <div>Saving - ₹ 120</div>
                    </Col>

                    <Col md={4} className="text-end">
                      <Button className=" mx-2 mb-2" size="sm" color="primary">
                        Estimated Price - ₹ 999
                      </Button>
                      <Button size="sm" className="mx-2 mb-2" color="primary">
                        Saving - ₹ 500
                      </Button>
                      <Button className=" mx-2 mb-2" size="sm" color="primary">
                        Estimated Price - ₹ 999
                      </Button>
                      <Button size="sm" className="mx-2 mb-2" color="primary">
                        Saving - ₹ 500
                      </Button>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card> */}
            <Card className="border-0 shadow-sm mb-4">
              <CardBody>
                <div className="mb-4">
                  <Row>
                    <Col md={8}>
                      <h5 className="mb-3">
                        <span className="me-2">🔬</span>
                        <strong>DIAGNOSTIC TESTS/INVESTIGATIONS</strong>
                      </h5>
                      <ul className="list-unstyled fs-7">
                        {diagnosticTests.map((item, index) => (
                          <li key={index}>
                            • <span className="fs-5">{item.type}</span>:
                            <div className="d-flex flex-wrap mt-2">
                              {item.tests.map((test, testIndex) => (
                                <Button
                                  key={testIndex}
                                  color="primary"
                                  className="rounded-pill px-3 py-1 me-2 mb-2"
                                >
                                  {test.name}
                                </Button>
                              ))}
                            </div>
                          </li>
                        ))}
                      </ul>
                    </Col>

                    <Col md={4} className="text-end">
                      {pricingInfo.map((item, index) => (
                        <Button
                          key={index}
                          className="mx-2 mb-2"
                          size="sm"
                          color="primary"
                        >
                          {item.label} - {item.value}
                        </Button>
                      ))}
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
            {/* Treatment Plan */}
            <Card className="border-0 shadow-sm mb-4">
              <CardBody>
                <div className="mb-4">
                  <h5 className="mb-3">
                    <span className="me-2">👨‍⚕️</span>
                    <strong>TREATMENT PLAN/PROCEDURE</strong>
                  </h5>
                  <ul className="list-unstyled text-primary">
                    <li>• CORONARY ANGIOPLASTY</li>
                  </ul>
                </div>
              </CardBody>
            </Card>
            {/* Additional Plans */}
            <Row className="mb-4 g-4">
              {/* DIET PLAN/NUTRITION SUPPLEMENT */}
              <Card className="border-0 shadow-sm mb-4">
                <CardBody>
                  <Col md={6}>
                    <h5 className="mb-3">
                      <span className="me-2">🍽️</span>
                      <strong>DIET PLAN/NUTRITION SUPPLEMENT</strong>
                    </h5>
                    <ul className="list-unstyled text-primary">
                      <li>• LUNGS DIET PLAN</li>
                    </ul>
                  </Col>
                </CardBody>
              </Card>
              <Card className="border-0 shadow-sm mb-4">
                <CardBody>
                  <Col md={6}>
                    <h5 className="mb-3">
                      <span className="me-2">🧘</span>
                      <strong>YOGA/PRANAYAM PLAN</strong>
                    </h5>
                    <ul className="list-unstyled text-primary">
                      <li>• EXERCISE PLAN</li>
                    </ul>
                  </Col>
                </CardBody>
              </Card>
            </Row>

            {/* Care Instructions */}
            <Row className="mb-4 g-4">
              <Card className="border-0 shadow-sm mb-4">
                <CardBody>
                  <Col md={6}>
                    <h5 className="mb-3">
                      <span className="me-2">👨‍⚕️</span>
                      <strong>NURSING CARE/PHYSIOTHERAPY</strong>
                    </h5>
                    <ul className="list-unstyled text-primary">
                      <li>• 24-HOUR EMERGENCY CARE - 1 MONTH</li>
                    </ul>
                  </Col>
                </CardBody>
              </Card>
              <Card className="border-0 shadow-sm mb-4">
                <CardBody>
                  <Col md={6}>
                    <h5 className="mb-3">
                      <span className="me-2">📱</span>
                      <strong> MONITORING DEVICE/HEALTH WATCH</strong>
                    </h5>
                    <ul className="list-unstyled text-primary">
                      <li>• Morepen - Blood pressure machine</li>
                    </ul>
                    {/* <div className="text-end">
                  <div>Estimated Price - ₹ 999</div>
                  <div>Saving - ₹ 500</div>
                </div> */}
                    <div className="text-end1">
                      <Button className=" mx-2 mb-2" size="sm" color="primary">
                        Estimated Price - ₹ 999
                      </Button>
                      <Button size="sm" className="mx-2 mb-2" color="primary">
                        Saving - ₹ 500
                      </Button>
                    </div>
                  </Col>
                </CardBody>
              </Card>
            </Row>

            {/* New Sections */}
            <Card className="border-0 shadow-sm mb-4">
              <CardBody>
                <div className="d-flex align-items-center mb-3">
                  <Bed className="me-2" size={20} />
                  <h5 className="mb-0">
                    {" "}
                    <strong>PALLIATIVE CARE </strong>
                  </h5>
                </div>
                <ul className="list-unstyled text-primary">
                  <li>• OXYGEN BED</li>
                </ul>
              </CardBody>
            </Card>

            <Card className="border-0 shadow-sm mb-4">
              <CardBody>
                <div className="d-flex align-items-center mb-3">
                  <Building2 className="me-2" size={20} />
                  <h5 className="mb-0">
                    {" "}
                    <strong>REFERRED HOSPITAL</strong>
                  </h5>
                </div>
                <ul className="list-unstyled text-primary">
                  <li>
                    • Indraprastha Apollo Hospitals, Mathura Rd, Jasola Vihar,
                    New Delhi, Delhi 110076
                  </li>
                </ul>
              </CardBody>
            </Card>

            <Card className="border-0 shadow-sm mb-4">
              <CardBody>
                <div className="d-flex align-items-center mb-3">
                  <UserCircle2 className="me-2" size={20} />
                  <h5 className="mb-0">
                    {" "}
                    <strong>REFERRED DOCTOR</strong>
                  </h5>
                </div>
                <ul className="list-unstyled text-primary">
                  <li>• NA</li>
                </ul>
              </CardBody>
            </Card>

            <Card className="shadow-sm">
              <CardBody>
                {/* Special Instructions Section */}
                <div className="mb-4">
                  <h2 className="h5 fw-bold mb-3">
                    SPECIAL INSTRUCTURE/ADVICE:
                  </h2>
                  <ListGroup flush className="border-0 ">
                    <ListGroupItem className="border-0 ps-4 text-primary">
                      • Nebulizer – Take 10 min. every day for 5 days
                    </ListGroupItem>
                    <ListGroupItem className="border-0 ps-4 text-primary">
                      • Advice – Drink plenty of water and sleep for 6-7 hours
                    </ListGroupItem>
                  </ListGroup>
                </div>

                {/* Follow Up Section */}
                <div className="bg-secondary text-white px-3 py-2 d-inline-block rounded mb-4">
                  <div className="d-flex align-items-center">
                    <Calendar className="me-2" size={20} />
                    <span className="fs-6">
                      <strong>FOLLOW UP:</strong> AFTER 6 DAYS / ON 18-AUG-2021
                    </span>
                  </div>
                </div>
              </CardBody>
            </Card>

            {/* disclimer */}
            <Card>
              <CardBody>
                {/* Doctor Info & Signature */}
                <Row className="mb-4">
                  <Col className="text-end">
                    <div className="mb-2">
                      <small className="text-muted fst-italic">signed</small>
                    </div>
                    <div className="fw-bold">DR. ASHOO</div>
                    <div>CARDIOLOGIST</div>
                    <div>MBBS, MD</div>
                  </Col>
                </Row>

                {/* Quote */}
                <Row className="mb-4">
                  <Col className="text-center">
                    <p className="fst-italic fs-5">
                      "We provide you with the best care assurance for all your
                      Medical expenses"
                    </p>
                  </Col>
                </Row>
                {/* Disclaimer */}
                <div className="border- shadow-s mb-4 p-0">
                  <div className="small text-muted">
                    <div className="d-flex align-items-center mb-2">
                      <FileWarning className="me-2" size={20} />
                      <strong className="fs-5">DISCLAIMER:</strong>
                    </div>
                    <p className="mb-1 fs- fs-sm-7">
                      THE PRESCRIPTION TO BE FOLLOWED IN THE REQUISITE DOSAGE
                      AND SPECIFIED TIME PERIOD ONLY. PLEASE GET IN TOUCH WITH
                      THE DOCTOR IN CASE OF ANY CLARIFICATIONS.
                    </p>
                    <p className="mb-1 fs- fs-sm-7">
                      FOR TELE-CONSULTATIONS, THE PRESCRIPTION GENERATED IS
                      BASED ON THE INPUTS GIVEN BY YOU DURING SERVICE AVAILED
                      AND NOT ON ANY PHYSICAL VERIFICATION.
                    </p>
                    <p className="mb-0 fs- fs-sm-7">
                      PLEASE VISIT A DOCTOR OR HOSPITAL IN CASE OF ANY
                      EMERGENCY.
                    </p>

                    {/* Footer */}
                    <div className="d-flex justify-content-between align-items-center border-top pt-3">
                      <small className="text-muted fs-6 fs-sm-6">
                        DIGITAL PRESCRIPTION GENERATED VIA HEALTH PASSPORT |
                        KOCHHAR CARE CONNECT PVT. LTD. © 2022
                      </small>
                      <img
                        // src="/api/placeholder/100/100"
                        src={logo}
                        alt="Care Connect Logo"
                        className="img-fluid"
                        style={{ maxHeight: "80px" }}
                      />
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default EPrescriptionView
