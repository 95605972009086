// import React from "react"
// import { initializeZego, loginRoom, sendCallSignal } from "./ZegoService"

// const Caller = () => {
//   const roomID = "room01" // Room ID
//   const userID = "user1" // Unique user ID for the caller
//   const userName = "Caller" // Caller name

//   const handleStartCall = async () => {
//     const zegoClient = initializeZego()

//     // Login to the room
//     await loginRoom(zegoClient, roomID, { userID, userName })

//     // Send a call signal to the receiver
//     await sendCallSignal(zegoClient, roomID, "user2") // Notify receiver (user2)
//   }

//   return (
//     <div>
//       <button
//         onClick={handleStartCall}
//         style={{
//           padding: "10px 20px",
//           backgroundColor: "blue",
//           color: "white",
//           border: "none",
//           borderRadius: "5px",
//           cursor: "pointer",
//         }}
//       >
//         Start Video Call
//       </button>
//     </div>
//   )
// }

// export default Caller

import React, { useState } from "react"
import VideoCall from "./VideoCall"
import { Button, Card, CardBody, Col } from "reactstrap"

const Caller = () => {
  const [startCall, setStartCall] = useState(false)
  const roomID = "room01" // Replace with dynamic Room ID logic if required.
  const userID = "user1" // Replace with unique user ID.
  const userName = "Caller"
  const appID = 1233739532 // Your ZEGOCLOUD appID
  const serverSecret = "bd4fc7c7e896e364e33a6395cb09d6ed" // Your server secret

  const handleStartCall = () => {
    setStartCall(true)
  }

  return (
    <div>
      <Col
      // lg="6"
      // xl="6" xxl="" sm="12" xs=""
      >
        <h3
          className=" d-flex flex-column align-items-center mb- mt-4"
          style={{
            color: "red",
            // fontSize: window.innerWidth < 768 ? 18 : 24,
          }}
        >
          Command Center
        </h3>
        <Card style={{ width: "100%", minHeight: "400px" }}>
          <CardBody>
            {!startCall ? (
              <div
                style={{
                  textAlign: "center",
                  // marginTop: "10px",
                  alignContent: "center",
                  textAlign: "center",
                  marginTop: "50px",
                }}
              >
                <h4 className="text-primary">Start a Video Call</h4>
                <Button
                  color="primary"
                  onClick={handleStartCall}
                  style={{ padding: "10px 20px" }}
                >
                  Start Call
                </Button>
              </div>
            ) : (
              <VideoCall
                roomID={roomID}
                userID={userID}
                userName={userName}
                appID={appID}
                serverSecret={serverSecret}
              />
            )}
          </CardBody>
        </Card>
      </Col>
    </div>
  )
}

export default Caller
