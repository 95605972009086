import { ZegoExpressEngine } from "zego-express-engine-webrtc"

const appID = 1233739532 // Replace with your AppID
const server = "wss://webliveroom1233739532-api.coolzcloud.com/ws" // WebSocket server URL
let zegoClient = null

export const initializeZego = (appID, server) => {
  zegoClient = new ZegoExpressEngine(appID, server)
  return zegoClient
}

export const loginRoom = async (roomID, userID, userName) => {
  const token =
    "04AAAAAGdZLj8AEDc2amE3dWlhN3FmZnEzZzQAoKjIuGEOmIngAS5+hcrEtU3jzN39g0uvNVWrejvxDIT8UGRitvy4a+3rSVZi6HX1EOw/fo7d0EvXghP8Hpfgf0Q1WYt0UQL95wyu8Et4pQO3OP6jM30jqAqyKeC4j4IZf+Svr/BlKdqdtrITwK4oTFanqDXSybXNh4jhPKZhQ8fRE9hEoSjt95QrpvBI7kmCExXI70Msei93rlOgf9u3NZg="
  return await zegoClient.loginRoom(roomID, token, { userID, userName })
}

export const startPublishingStream = async (streamID, videoElement) => {
  const localStream = await zegoClient.createStream({
    camera: { video: true, audio: true },
  })
  videoElement.srcObject = localStream
  videoElement.play()
  await zegoClient.startPublishingStream(streamID, localStream)
}

export const startPlayingStream = async (streamID, videoElement) => {
  const remoteStream = await zegoClient.startPlayingStream(streamID)
  videoElement.srcObject = remoteStream
  videoElement.play()
}
