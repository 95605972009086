// import React, { useEffect } from "react"
// import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt"

// const VideoCall = ({ roomID, userID, userName, appID, serverSecret }) => {
//   useEffect(() => {
//     try {
//       const token = ZegoUIKitPrebuilt.generateKitTokenForTest(
//         appID,
//         serverSecret,
//         roomID,
//         userID.toString(), // Ensure userID is a string
//         userName.toString() // Ensure userName is a string
//       )

//       const zp = ZegoUIKitPrebuilt.create(token)
//       zp.joinRoom({
//         container: document.querySelector("#root"),
//         sharedLinks: [
//           {
//             name: "Join as Host",
//             url: `${window.location.origin}${window.location.pathname}?roomID=${roomID}`,
//           },
//         ],
//         scenario: {
//           mode: ZegoUIKitPrebuilt.OneONoneCall,
//         },
//       })
//     } catch (error) {
//       console.error("Error during video call setup: ", error)
//     }
//   }, [appID, serverSecret, roomID, userID, userName])

//   return <div id="root" style={{ width: "100%", height: "100%" }}></div>
// }

// export default VideoCall

import React, { useEffect } from "react"
import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt"
import { Card } from "reactstrap"
const VideoCall = ({ roomID, userID, userName, appID, serverSecret }) => {
  // Function to detect mobile devices
  function isMobileDevice() {
    return /Android|iPhone|iPad|iPod/i.test(navigator.userAgent)
  }

  const initializeCall = async () => {
    const token = ZegoUIKitPrebuilt.generateKitTokenForTest(
      appID,
      serverSecret,
      roomID,
      userID,
      userName
    )
    // Detect whether the device is mobile
    const showCameraToggle = isMobileDevice()
    const zp = ZegoUIKitPrebuilt.create(token)

    zp.joinRoom({
      container: document.getElementById("zego-container"),
      sharedLinks: [
        {
          name: "Join Call",
          url: `${window.location.origin}${window.location.pathname}?roomID=${roomID}`,
        },
      ],
      scenario: {
        mode: ZegoUIKitPrebuilt.VideoConference, // For 1:1 calls
        // mode: ZegoUIKitPrebuilt.OneONoneCall, // For 1:1 calls
        // mode: ZegoUIKitPrebuilt.VideoResolution_720P, // For 1:1 calls
      },
      // showScreenSharingButton: false,
      // showMyCameraToggleButton: true,

      // videoResolutionDefault: ZegoUIKitPrebuilt.VideoResolution_720P, // Set HD resolution
      videoResolutionDefault: ZegoUIKitPrebuilt.VideoResolution_720P, // Set video resolution to 1080P
      turnOnMicrophoneWhenJoining: true, // Turn on the microphone by default
      turnOnCameraWhenJoining: true, // Turn on the camera by default
      useFrontFacingCamera: true, // Use the front-facing camera
      showScreenSharingButton: false, // Optional: enable screen sharing
      showMyCameraToggleButton: true, // Allow toggling the camera
      // Detect whether the device is mobile
      // showMyCameraToggleButton: showCameraToggle, // Show camera toggle only on mobile devices
      showMyMicrophoneToggleButton: true, // Allow toggling the microphone
      useFrontFacingCamera: true,
    })
  }

  useEffect(() => {
    initializeCall()
  }, [])

  return (
    // <div
    //   id="zego-container"
    //   //    style={{ width: "100vw", height: "100vh" }}

    // />
    <Card>
      <div
        id="zego-container"
        style={{
          // marginTop: "100px",
          // width: "350px", // Set a reasonable width and height for dragging
          height: "400px",
          // border: "1px solid #ccc",
          // borderRadius: "8px",
          overflow: "hidden",
          position: "absolute", // Allow positioning within the parent container
        }}
        // style={{
        //   width: "100vw",
        //   height: "100vh",
        //   position: "absolute",
        //   top: 0,
        //   left: 0,
        // }}
      />
    </Card>
  )
}

export default VideoCall
